import React from "react";
import { useState, useRef } from "react";
import api from "../shared/utils/api";
import { toast } from "react-toastify";
import FormGroupInput from "../shared/components/FormGroupInput";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { ERROR_MESSAGE_DURATION } from "../shared/Constants";

function ChangePassword(props) {
    const [validated, setValidated] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const toastID = useRef(null);

    const handleCancel = (e) => {
        e.preventDefault();
        props.cancelCallback();
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
            return;
        }

        setValidated(true);

        toastID.current = toast("Saving...", { autoClose: false });

        api.post("/change-password", {
            email: props.email,
            oldPassword: oldPassword,
            newPassword: newPassword,
        }).then(
            () => {
                toast.update(toastID.current, {
                    render: "Password Changed!",
                    type: "success",
                    autoClose: 3000,
                });
                props.successCallback();
            },
            (error) => {
                toast.update(toastID.current, {
                    render: "Error Changing Password! " + error.message,
                    type: "error",
                    autoClose: ERROR_MESSAGE_DURATION,
                });
            }
        );
    };

    return (
        <div>
            <div class="form-container">
                <h4>Change Password</h4>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                        <FormGroupInput
                            label="Email"
                            name="email"
                            type="text"
                            value={props.email}
                            disabled={true}
                        />
                    </Row>
                    {!props.isAdmin && (
                        <Row>
                            <FormGroupInput
                                label="Old Password"
                                name="oldPassword"
                                type="password"
                                value={oldPassword}
                                invalidFeedback="Password must be at least 8 characters long, contain an uppercase and lowercase letter, a number, and a special character."
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                                onChange={(e) => setOldPassword(e.target.value)}
                                className="mb-3"
                            />
                        </Row>
                    )}
                    <Row>
                        <FormGroupInput
                            label="New Password"
                            name="newPassword"
                            type="password"
                            value={newPassword}
                            invalidFeedback="Password must be at least 8 characters long, contain an uppercase and lowercase letter, a number, and a special character."
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </Row>
                    <Row>
                        <Form.Group class="form-group">
                            <Button
                                className="btn-standard margin-right-10"
                                type="submit"
                            >
                                Save
                            </Button>
                            <Button
                                className="btn-standard danger"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Form.Group>
                    </Row>
                </Form>
            </div>
        </div>
    );
}

export default ChangePassword;
