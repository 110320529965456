import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../shared/utils/api';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

export default function AddQuizQuestion() {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [questionOrder, setQuestionOrder] = useState(0);

    const navigate = useNavigate();

    const params = useParams();

    const { id } = params;

    const handleSubmit = (e) => {
        e.preventDefault();

        api.post('/quiz-questions', {
            quizID: parseInt(id),
            questionTitle: title,
            questionContent: content,
            questionOrder: parseInt(questionOrder)
        }).then(
            (response) => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    };

    return (
        <div className="container">
            <h2>Add Quiz Question</h2>
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label htmlFor="questionTitle">Title</label>
                    <br />
                    <input
                        name="questionTitle"
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="questionContent">Content</label>
                    <br />
                    <textarea
                        name="questionContent"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}>
                    </textarea>
                </div>
                <div className="input-container">
                    <label htmlFor="questionOrder">Order</label>
                    <br />
                    <input
                        name="questionOrder"
                        type="number"
                        value={questionOrder}
                        onChange={(e) => setQuestionOrder(e.target.value)}
                    />
                </div>
                <br />
                <button type="submit" className="btn-submit">Save</button>
            </form>
        </div>
    );
};
