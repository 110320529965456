import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../shared/utils/api";
import SignUpComplete from "./SignUpComplete";
import { toast } from 'react-toastify';
import FormGroupInput from '../shared/components/FormGroupInput';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { ERROR_MESSAGE_DURATION } from '../shared/Constants';
import { generateUsername } from "../shared/utils/usernameHelper";

function SignUp() {
    const [validated, setValidated] = useState(false);
    const [passwordMatchValid, setPasswordMatchValid] = useState(true);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [signUpComplete, setSignUpComplete] = useState(false);

    const navigate = useNavigate();

    const passwordMatching = (value) => {
        setConfirmPassword(value);

        if (password === value) {
            setPasswordMatchValid(true);
            return;
        }

        setPasswordMatchValid(false);
    }

    const handleCancel = (e) => {
        e.preventDefault();
        navigate("/login");
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        // There is a fundamental problem with the way form validation works here
        // To see the issue:
        // 1. Sign up with all fields filled in except missing email. Include matching passwords.
        //    This is to trigger the form validation css.
        // 2. Change the confirmation password field to be different from the password field.
        //    Note how the confirmation password field will get a red warning message but the
        //    border will not turn red. 
        // This ends up making the browser think that the entire form is valid, so we check
        // password matching separately.
        // There is no way to do this with the current form validation setup because we rely on
        // native HTML input validation and we need to compare two fields which would require
        // a full overhaul to javascript validation.
        // TODO: potential fix to look into is to toggle the valid status on the whole form
        //       instead of the individual inputs.
        if (!passwordMatchValid) {
            toast.error("Passwords must match.", { autoClose: ERROR_MESSAGE_DURATION })
            return;
        }
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return; // Return if validation failed
        }

        setValidated(true);

        var generatedUsername = generateUsername(email)
        api.post('/signup', {
            username: generatedUsername,
            password: password,
            email: email,
            // TODO: input sanitization
            phoneNumber: "+1" + phoneNumber,
            firstName: firstName,
            lastName: lastName
        }).then(
            (response) => {
                setSignUpComplete(true);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    const mainContent = () => {
        if (signUpComplete) {
            return <SignUpComplete signUpCallback={() => navigate("/login")} />;
        }

        return (
            <div class="form-container center">
                <h4>Sign Up</h4>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                        <FormGroupInput
                            controlId="email"
                            label="Email"
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            required={true}
                            invalidFeedback="Please enter a valid email address."
                        />
                    </Row>
                    <Row>
                      <FormGroupInput
                        controlId="phoneNumber"
                        label="Phone Number"
                        required={true}
                        type="tel"
                        invalidFeedback="Must provide a phone number in the format 123-456-7890 or 1234567890."
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        pattern="(\d{3}-?\d{3}-?\d{4})|(\d{10})"
                      />
                    </Row>
                    <Row>
                        <FormGroupInput
                            controlId="password"
                            label="Password"
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            required={true}
                            invalidFeedback="Password must be at least 8 characters long, contain an uppercase and lowercase letter, a number, and a special character."
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                        />
                    </Row>
                    <Row>
                        <FormGroupInput
                            controlId="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            onChange={(e) => passwordMatching(e.target.value)}
                            required={true}
                            invalidFeedback="Passwords must match."
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                            isInvalid={!passwordMatchValid}
                        />
                    </Row>
                    <Row>
                        <FormGroupInput
                            controlId="firstName"
                            label="First Name"
                            type="text"
                            onChange={(e) => setFirstName(e.target.value)}
                            required={true}
                            placeholder={"First Name"}
                            pattern="[a-zA-Z ]+"
                            invalidFeedback="Names may contain alphabetical characters only."
                        />
                        <FormGroupInput
                            controlId="lastName"
                            label="Last Name"
                            type="text"
                            onChange={(e) => setLastName(e.target.value)}
                            required={true}
                            pattern="[a-zA-Z ]+"
                            placeholder={"Last Name"}
                            invalidFeedback="Names may contain alphabetical characters only."
                        />
                    </Row>
                    <Row>
                        <Form.Group class="form-group">
                            <Button
                                className="btn-standard margin-right-10"
                                type="submit">Sign Up</Button>
                            <Button
                                className="btn-standard danger"
                                onClick={handleCancel}>Cancel</Button>
                        </Form.Group>
                    </Row>
                </Form>
            </div>
        );
    }

    return mainContent();
}

export default SignUp;
