import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../../shared/utils/api";
import ReactDataTables from "../../shared/components/ReactDataTables";
import { toast } from "react-toastify";
import { ERROR_MESSAGE_DURATION } from "../../shared/Constants";
import { LuPlusCircle } from "react-icons/lu";


function HomeInspections() {
  const [homeInspections, setHomeInspections] = useState([]);

  let [searchParams] = useSearchParams();

  const fetchHomeInspections = () => {
    api.get("/home-inspections").then(
      (response) => {
        if (response === null) return;
        if (response === undefined) return;

        setHomeInspections(response);
      },
      (error) => {
        toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
      },
    );
  };

  const navigate = useNavigate();

  const handleRowClick = (rowData) => {
    navigate("/home-inspection/" + rowData.homeInspectionID.toString());
  };

  const handleAdd = () => {
    navigate("add");
  };

  useEffect(() => {
    fetchHomeInspections();
  }, []);

  const getBadge = (status) => {
    const baseClasses = "text-sm px-3 pt-2 pb-1 rounded-full bold";
    const badgeStyles = {
      "Requested": { badgeClass: `${baseClasses} bg-blue-200 text-blue-900`, dotClass: "bg-blue-700" },
      "Request Verified": { badgeClass: `${baseClasses} bg-yellow-200 text-yellow-900`, dotClass: "bg-yellow-700" },
      "Assigned": { badgeClass: `${baseClasses} bg-purple-200 text-purple-900`, dotClass: "bg-purple-700" },
      "In Progress": { badgeClass: `${baseClasses} bg-orange-200 text-orange-900`, dotClass: "bg-orange-700" },
      "Changes Requested": { badgeClass: `${baseClasses} bg-red-200 text-red-900`, dotClass: "bg-red-700" },
      "Changes Submitted": { badgeClass: `${baseClasses} bg-teal-200 text-teal-900`, dotClass: "bg-teal-700" },
      "Approval Required": { badgeClass: `${baseClasses} bg-indigo-200 text-indigo-900`, dotClass: "bg-indigo-700" },
      "Completed": { badgeClass: `${baseClasses} bg-green-100 text-green-800`, dotClass: "bg-green-700" },
    };

    return badgeStyles[status] || { badgeClass: "fs-badge fs-badge-danger", dotClass: "bg-red-900" };
  }

  const columns = [
    { data: "address", title: "Address" },
    { data: "city", title: "City" },
    { data: "agencyName", title: "Agency",
      render: function (data) {
        return data ? data.split(',').join(', ') : '';
      }
    },
    {
      data: "assignedInspectorFirstName",
      title: "Assigned Assessor",
      render: function (data, type, row) {
        return data
          ? row.assignedInspectorFirstName + " " + row.assignedInspectorLastName
          : "Unassigned";
      },
    },
    {
      data: "auditTitle",
      title: "Assessment Type",
      render: function (data, type, row) {
        return row.auditTitle;
      },
    },
    {
      data: "homeInspectionStatus",
      title: "Status",
      render: function (data, type) {
        if (type === "filter" || type === "sort") return data;
        const { badgeClass, dotClass } = getBadge(data);
        return (
          `<span class="inline-flex items-center ${badgeClass}">` +
          `<span class="inline-block w-2 h-2 -mt-1 mr-2 rounded-full ${dotClass}"></span>` + // Add background color class to dot
          `${data}</span>`
        );
      },
    },
  ];

  const HomeInspectionTable = () => {
    const searchParamsFilter = searchParams.get("filter");
    const searchFilter = searchParamsFilter
      ? { search: '"' + searchParamsFilter + '"' }
      : {};
    return (
      <ReactDataTables
        data={homeInspections}
        columns={columns}
        search={searchFilter}
        paging={true}
        scrollY={"65vh"}
        pageLength={100}
        rowClickCallback={handleRowClick}
        className="mt-4"
      />
    );
  };

  return (
    <div>
      <button class="btn-standard float-right" onClick={handleAdd}>
        <LuPlusCircle className="mr-1 -mt-0.5 h-4 w-4 inline-block" /> New Assessment
      </button>
      <h2>FireSmart Assessments</h2>
     
      <HomeInspectionTable />
    </div>
  );
}

export default HomeInspections;
