import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import api from '../../../shared/utils/api';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

export default function QuizQuestion() {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [questionOrder, setQuestionOrder] = useState(0);
    const [answers, setAnswers] = useState([]);

    const navigate = useNavigate();
    const params = useParams();

    const { id, questionID } = params;

    useEffect(() => {
        const fetchQuizQuestion = () => {
            api.get('/quiz-questions/' + questionID).then(
                (response) => {
                    if (response === null) return;
                    if (response === undefined) return;
                    setTitle(response.questionTitle);
                    setContent(response.questionContent);
                    setQuestionOrder(response.questionOrder);
                },
                (error) => {
                    toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
                }
            );
        }
    
        const fetchQuizAnswers = () => {
            api.get('/quiz-answers?questionID=' + questionID).then(
                (response) => {
                    if (response === null) return;
                    if (response === undefined) return;
                    //sort the answers by answerOrder
                    response.sort((a, b) => (a.answerOrder > b.answerOrder) ? 1 : -1)
                    setAnswers(response);
                },
                (error) => {
                    toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
                }
            );
        }

        fetchQuizQuestion();
        fetchQuizAnswers();
    }, [questionID]);

    const handleSubmit = (e) => {
        e.preventDefault();

        api.post('/quiz-questions', {
            quizQuestionID: parseInt(questionID),
            questionTitle: title,
            questionContent: content,
            questionOrder: parseInt(questionOrder)
        }).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    const handleDelete = (e) => {
        e.preventDefault();
        
        api.delete('/quiz-questions/' + questionID).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    return (
        <div>
            <h2>{title}</h2>
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label htmlFor="questionTitle">Question Title</label>
                    <br />
                    <input
                        type="text"
                        name="questionTitle"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div className="input-container">
                    <label htmlFor="questionContent">Question Content</label>
                    <br />
                    <textarea
                        value={content}
                        name="questionContent"
                        onChange={(e) => setContent(e.target.value)} />
                </div>
                <div className="input-container">
                    <label htmlFor="questionOrder">Question Order</label>
                    <br />
                    <input
                        type="number"
                        value={questionOrder}
                        name="questionOrder"
                        onChange={(e) => setQuestionOrder(e.target.value)} />
                </div>
                <br />
                <button type="submit" className="btn-submit">Save</button>
                <button onClick={handleDelete} className="btn-delete">Delete</button>
            </form>

            <h3>Answers</h3>
            <ul>
                {answers.map((answer) => (
                    <li key={answer.quizAnswerID}>
                        <Link to={`/quizzes/${id}/questions/${questionID}/answers/${answer.quizAnswerID}`}>{answer.answerContent}</Link>
                    </li>
                ))}
            </ul>

            <button onClick={() => navigate(`/quizzes/${id}/questions/${questionID}/answers/add`)} className="btn-new-page">New Answer</button>
            <br />
            <br />
        </div>
    );
};
