import { useEffect, useRef } from "react";
import DataTables from "datatables.net-bs5";

function ReactDataTables({ rowClickCallback, customFilterValue, customFilterCallback, className, ...props }) {
    const tableRef = useRef();    

    useEffect(() => {
        const table = new DataTables(tableRef.current,
            {
                ...props
            });

        if (rowClickCallback) {
            table.on('click', 'tbody tr', function () {
                const data = table.row(this).data();
                if (data === undefined) return;
                rowClickCallback(data);
            });
        }
        return function () {
            table.destroy()
        }
    }, [])

    useEffect(() => {
        if (customFilterCallback) {
            const table = new DataTables(tableRef.current)
            customFilterCallback(table);
        }
    }, [customFilterValue]);

    return (
        <div className={className}>
            <table className="table" ref={tableRef} ></table>
        </div>
    );
}

export default ReactDataTables;
