import { provinces } from '../Constants';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

function FormGroupProvinceDropdownInput({ value, onChange, required, placeHolderRequired }) {
    return (
        <Form.Group as={Col} controlId="province" class="form-group">
            <Form.Label className="font-bold">Province</Form.Label>
            <Form.Select
                required={required}
                class="form-select"
                pattern="(AB|BC|MB|NB|NL|NS|NT|NU|ON|PE|QC|SK|YT)"
                onChange={onChange}
                value={value}
            >
                {provinces.map((value) => (
                    <option key={value} value={value}>{value}</option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                Must select one of the provinces listed.
            </Form.Control.Feedback>
        </Form.Group>
    );
}

export default FormGroupProvinceDropdownInput;