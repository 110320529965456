import React from "react";
import { useRef } from "react";
import QuizQuestionPreview from "./Questions/QuizQuestionPreview";
import { toast } from "react-toastify";

export default function QuizPreview(props) {
    const { quizQuestions, questionIndex } = props;

    const toastID = useRef(null);

    const correctAnswerCallback = (isCorrect) => {
        if (isCorrect) {
            if (toastID.current === null) {
                toastID.current = toast("Correct!", { autoClose: 2000, type: "success" });
            }
            else {
                toast.update(toastID.current, { render: "Correct!", type: "success", autoClose: 2000 })
            }
        }

        if (!isCorrect) {
            if (toastID.current === null) {
                toastID.current = toast("Incorrect Answer", { autoClose: 2000, type: "warning" });
            }
            else {
                toast.update(toastID.current, { render: "Incorrect Answer", type: "warning", autoClose: 2000 })
            }
        }
    }

    const nextQuestionCallback = () => {
        toast.dismiss(toastID.current);
        props.nextQuestionCallback();
    }

    const submitQuizCallback = () => {
        toast.dismiss(toastID.current);
        props.submitQuizCallback();
    }

    return (
        <div class="page-preview">
            <h2 class="no-margin-top">{props.quiz.quizTitle}</h2>
            <QuizQuestionPreview
                quizAttemptID={props.quizAttemptID}
                question={quizQuestions[questionIndex]}
                nextQuestionCallback={nextQuestionCallback}
                finalQuestion={questionIndex == quizQuestions.length - 1}
                submitQuizCallback={submitQuizCallback}
                correctAnswerCallback={correctAnswerCallback} />
        </div>
    );
}
