import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import FormGroupInput from "../../shared/components/FormGroupInput";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRef } from "react";
import api from "../../shared/utils/api";
import { ERROR_MESSAGE_DURATION } from "../../shared/Constants";

function HomeInspectionLookup() {
    const [validated, setValidated] = useState(false);
    const [homeInspectionAccessCode, setHomeInspectionAccessCode] =
        useState("");
    const [homeOwnerEmailAddress, setHomeOwnerEmailAddress] = useState("");

    const navigate = useNavigate();

    const toastID = useRef(null);

    const getHomeInspection = (homeOwnerEmail, homeInspectionAccessCode) => {
        toastID.current = toast("Loading...", { autoClose: false });
        api.get(
            "/home-inspections/" +
                homeOwnerEmail +
                "/" +
                homeInspectionAccessCode
        ).then(
            (response) => {
                if (response === null) return;
                toast.update(toastID.current, {
                    render: "Home Assessment Retrieved!",
                    type: "success",
                    autoClose: 1000,
                });
                navigate("retrieved", { state: { homeInspection: response } });
            },
            (error) => {
                toast.update(toastID.current, {
                    render: "Error Retrieving Home Assessment: " + error,
                    type: "error",
                    autoClose: ERROR_MESSAGE_DURATION,
                });
            }
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
            return; // Return if validation failed
        }

        setValidated(true);
        getHomeInspection(homeOwnerEmailAddress, homeInspectionAccessCode);
    };

    const handleForgotAccessCode = (e) => {
        e.preventDefault();
        navigate("/forgot-access-code");
    };

    return (
        <div>
            <div class="row">
                <h2>View Home Assessment</h2>
                <p>Enter your email address and access code to view the status of your home assessment.</p>
                <div class="col">
                    <div class="form-container">

                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                            className="pt-3"
                        >
                            <Row className="mb-3">
                                <FormGroupInput
                                    controlId="homeOwnerEmailAddress"
                                    label="Email Address"
                                    required={true}
                                    onChange={(e) =>
                                        setHomeOwnerEmailAddress(e.target.value)
                                    }
                                    type="email"
                                    invalidFeedback="Please enter a valid email address."
                                />
                            </Row>
                            <Row className="mb-3">
                                <FormGroupInput
                                    controlId="homeInspectionAccessCode"
                                    label="Access Code"
                                    required={true}
                                    onChange={(e) =>
                                        setHomeInspectionAccessCode(
                                            e.target.value
                                        )
                                    }
                                    type="text"
                                    invalidFeedback="Please enter an access code that is alphabetical characters only and 6 characters long."
                                    pattern="^[a-zA-Z]{6}$"
                                />
                            </Row>
                            <Row className="justify-content-between">
                                <Form.Group class="form-group">
                                    <Button
                                        className="btn-standard margin-right-10"
                                        type="submit"
                                    >
                                        Retrieve Home Assessment
                                    </Button>
                                    <Button
                                        className="ml-2 bg-transparent border-0 !text-sm text-black p-0"
                                        type="submit"
                                        onClick={handleForgotAccessCode}
                                    >
                                        Forgot Access Code?
                                    </Button>
                                </Form.Group>                                
                            </Row>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeInspectionLookup;
