import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../../shared/utils/api';
import FormControlInput from '../../shared/components/FormControlInput';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../shared/Constants';

function Audit() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [certificationID, setCertificationID] = useState(0);
    const [auditModules, setAuditModules] = useState([]);

    const navigate = useNavigate();

    const params = useParams();

    const { id } = params;

    useEffect(() => {
        const fetchAudit = () => {
            api.get('/audits/' + id).then(
                (response) => {
                    if (response === null) return;
                    if (response === undefined) return;
                    setTitle(response.auditTitle);
                    setDescription(response.auditDescription);
                    setCertificationID(response.certificationID);
                },
                (error) => {
                    toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
                }
            );
        }

        const fetchAuditModules = () => {
            api.get('/audit-modules?auditID=' + id).then(
                (response) => {
                    if (response === null) return;
                    if (response === undefined) return;
                    //sort the questions by questionOrder
                    response.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1)
                    setAuditModules(response);
                },
                (error) => {
                    toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
                });
        }

        fetchAudit();
        fetchAuditModules();
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();

        api.post('/audits', {
            auditID: parseInt(id),
            auditTitle: title,
            auditDescription: description,
            certificationID: certificationID
        }).then(
            () => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    const handleDelete = (e) => {
        e.preventDefault();

        api.delete('/audits/' + id).then(
            () => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    return (
        <div>
            <h2>Assessment</h2>
            <form onSubmit={handleSubmit}>
                <FormControlInput label="Assessment Title" type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                <FormControlInput label="Assessment Description" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />

                <button type="submit" className="btn-submit">Save</button>
                <button onClick={handleDelete} className="btn-delete">Delete</button>
            </form>
            <br />
            <h3>Modules</h3>
            <ul>
                {auditModules.map((module) => (
                    <li key={module.auditModuleID}>
                        <Link to={`/audits/${id}/modules/${module.auditModuleID}`}>{module.moduleTitle}</Link>
                    </li>
                ))}
            </ul>

            <button onClick={() => navigate(`/audits/${id}/modules/add`)} className="btn-new-question">New Module</button>
        </div>
    );
}

export default Audit;
