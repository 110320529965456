import React, { useState } from 'react';
import FormControlInput from '../shared/components/FormControlInput';

function HomeInspectionFileUpload({ handleFileChange, handleFileCancel, handleFileSubmit, file }) {
    return (
        <div className="mb-2">
            <form>
                <FormControlInput
                    label="Upload Image"
                    type="file"
                    labelBolded
                    onChange={handleFileChange}
                    accept="image/*"
                    name="file" />
                {file && <button class="btn btn-standard margin-right-10" type="button" onClick={handleFileSubmit}>Upload</button>}
                {file && <button class="btn btn-danger" type="reset" onClick={handleFileCancel}>Cancel</button>}
            </form>
        </div>
    );
}

export default HomeInspectionFileUpload;
