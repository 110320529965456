import React, { useState } from "react";
import api from "../shared/utils/api";
import { toast } from "react-toastify";
import { ERROR_MESSAGE_DURATION } from "../shared/Constants";

function FileUpload(props) {
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        // TODO: didn't have time to support multiple files, but should work with some tweaks
        // example: https://jsfiddle.net/41go76g4/

        if (event.target.files[0].size > 100000000) {
            toast.error("File size must be less than 100MB", {
                autoClose: ERROR_MESSAGE_DURATION,
            });
            //setFile(null);
        }

        if (
            !event.target.files[0].type.match("image/*") &&
            !event.target.files[0].type.match("video/*")
        ) {
            toast.error("File must be an image or video", {
                autoClose: ERROR_MESSAGE_DURATION,
            });
            //setFile(null);
        }

        var reader = new FileReader();

        // Closure to capture the file information.
        reader.onload = (function () {
            return function (e) {
                props.uploadCallback(e.target.result);
            };
        })(file);

        // Read in the image file as a data URL.
        reader.readAsDataURL(event.target.files[0]);

        setFile(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("file", file);
        formData.append("pageID", props.pageID);
        formData.append("courseID", props.courseID);
        formData.append("courseModuleID", props.courseModuleID);

        api.postFormData("/upload", formData).then(
            (response) => {
                props.uploadCallback(response.presignedURL);
                toast.success("File uploaded successfully", {
                    autoClose: 2000,
                });
            },
            (error) => {
                toast.error(error.message, {
                    autoClose: ERROR_MESSAGE_DURATION,
                });
            }
        );
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input
                    type="file"
                    name="file"
                    onChange={handleFileChange}
                    accept="image/*,video/*"
                />
                <button type="button" onClick={handleSubmit}>
                    Upload
                </button>
            </form>
        </div>
    );
}

export default FileUpload;
