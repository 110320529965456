import React from "react";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CourseListing from "./CourseListing";
import api from '../../shared/utils/api';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../shared/Constants';
import { AiOutlineSearch } from "react-icons/ai";

function CourseCatalog() {
    const [courses, setCourses] = useState([]);
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [search, setSearch] = useState('');

    const fetchCourses = () => {
        api.get('/courses-user-progress').then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setCourses(response);
                setFilteredCourses(response);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    const navigate = useNavigate();

    const handleRowClick = (course) => {
        navigate(course.courseID.toString(), { state: { course: course } });
    }

    useEffect(() => {
        fetchCourses()
    }, []);

    useEffect(() => {
        setFilteredCourses(searchCourses(search));
    }, [search]);

    const handleAdd = () => {
        navigate('add');
    }

    const searchCourses = (searchQuery) => {
        return courses.filter((course) => {
            return course.course.courseTitle.toLowerCase().includes(searchQuery.toLowerCase()) || !searchQuery;
        });
    };

    return (
        <div>
            <h1 class="small-header">FireSmart Training</h1>

            <div className="relative max-w-[200px] mb-3 md:ml-auto">
            <AiOutlineSearch 
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" 
                    size={20}
                />
                <input 
                    className="search w-full pl-10 pr-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500" 
                    type="text" 
                    value={search} 
                    onChange={(e) => setSearch(e.target.value)} 
                    placeholder="Search"
                />
               
            </div>

            <div id="course-listing-container" className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {filteredCourses.map((course, ) =>
                    <CourseListing course={course.course} status={course.status} key={course.course.courseID}
                        handleRowClick={handleRowClick} />
                )}
            </div>

            <br />

            {/* //TODO: We need to figure out how to prevent a clever user from going to the course edit page
            //#24 - https://github.com/WovelSoftware/firesmart-app/issues/24 */}
            {/* <button onClick={handleAdd}>Add</button> */}
        </div>
    );
}

export default CourseCatalog;
