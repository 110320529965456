import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../../shared/utils/api';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../shared/Constants';

function CourseEdit() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [modules, setModules] = useState([]);

    const navigate = useNavigate();

    const params = useParams();
    //get the id passed in from the URL path
    const { id } = params;

    //look up the course by id
    const fetchCourse = () => {
        api.get('/courses/' + id).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setTitle(response.courseTitle);
                setDescription(response.courseDescription);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    const fetchCourseModules = () => {
        api.get('/modules?courseID=' + id).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                //sort the modules by moduleOrder
                response.sort((a, b) => (a.moduleOrder > b.moduleOrder) ? 1 : -1)
                setModules(response);
            },
            (error) => {
               toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    useEffect(() => {
        fetchCourse()
        fetchCourseModules()
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        api.post('/courses', { courseID: parseInt(id), courseTitle: title, courseDescription: description }).then(
            (response) => {
                navigate("/");
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    };

    const handleDelete = (e) => {
        e.preventDefault();

        api.delete('/courses/' + id).then(
            (response) => {
                navigate("/");
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <h2>{title}</h2>
                <div className="input-container">
                    <label htmlFor="courseTitle">Title</label>

                    <br />
                    <input
                        name="courseTitle"
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="courseDescription">Description</label>
                    <br />
                    <textarea
                        name="courseDescription"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}>
                    </textarea>
                </div>
                <div>
                    <h3>Modules</h3>
                    <ul>
                        {modules.map((module) => (
                            <li key={module.courseModuleID}>
                                <Link to={"/courses/" + id + "/modules/" + module.courseModuleID}>{module.moduleTitle}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <button type="button" className="btn-new-module" onClick={() => navigate("/courses/" + id + "/modules/add")}>New Module</button>
                <br />
                <br />
                <button type="submit" className="btn-submit">Save</button>
                <button type="button" className="btn-delete" onClick={handleDelete}>Delete</button>
            </form>
        </div>
    );
}

export default CourseEdit;
