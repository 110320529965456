import React from "react";

function ImageContainer({ children }) {
    if (!children) return null;
    if (children.length === 0) return null;

    return (
        <div className="form-group">
            <label className="form-label font-weight-bold">Images Attached</label>
            <div className="row">
                {children}
            </div>
        </div>
    );
}

export default ImageContainer;
