import { useState } from 'react';
import api from '../../shared/utils/api';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import FormGroupInput from '../../shared/components/FormGroupInput';
import FormGroupProvinceDropdownInput from '../../shared/components/FormGroupProvinceDropdownInput';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { ERROR_MESSAGE_DURATION } from '../../shared/Constants';
import PostalCodeInput from '../../shared/components/PostalCodeInput';

export default function HomeInspectionForgot() {
    const [validated, setValidated] = useState(false);
    const [homeOwnerEmailAddress, setHomeOwnerEmailAddress] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [province, setProvince] = useState('BC');
    const [postalCode, setPostalCode] = useState('');

    const requestAccessCode = () => {
        // TODO: input validation and sanitization
        api.post('/forgot-access-code', {
            homeOwnerEmailAddress: homeOwnerEmailAddress,
            address: address,
            city: city,
            province: province,
            // TODO: postal code sanitization (7 characters, 1 space in middle)
            postalCode: postalCode
        }).then(
            (response) => {
                toast.success("The acess code has been re-sent to: " + homeOwnerEmailAddress, {
                    autoClose: 2000
                });
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
            return; // Return if validation failed
        }

        setValidated(true);

        requestAccessCode();
    }
    return (
        <div>
            <div class="row">
                <h2>Forgot Access Code</h2>
                <p>Enter your email address and exact address to re-send the access code.</p>
                <div class="col">
                    <div class="form-container">    
                        <Form className="pt-3" noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row>
                                <FormGroupInput
                                    controlId="homeOwnerEmailAddress"
                                    label="Email Address"
                                    required={true}
                                    onChange={(e) => setHomeOwnerEmailAddress(e.target.value)}
                                    type="email"
                                    invalidFeedback="Please enter a valid email address."
                                />
                            </Row>
                            <Row>
                                <FormGroupInput
                                    controlId="address"
                                    label="Address"
                                    required={true}
                                    onChange={(e) => setAddress(e.target.value)}
                                    type="text"
                                    invalidFeedback="Must provide an address with allowed special characters .:-_', and allowed spaces."
                                    pattern="^[a-zA-Z0-9 .:,-_']+$"
                                />
                            </Row>
                            <Row>
                                <FormGroupInput
                                    controlId="city"
                                    label="City"
                                    required={true}
                                    type="text"
                                    invalidFeedback="Must provide a city with allowed special characters .:-_', and allowed spaces."
                                    onChange={(e) => setCity(e.target.value)}
                                    pattern="^[a-zA-Z0-9 .:,-_']+$"
                                />
                            </Row>
                            <Row>
                                <FormGroupProvinceDropdownInput
                                    value={province}
                                    onChange={(e) => setProvince(e.target.value)}
                                    required={true}
                                />
                            </Row>
                            <Row>
                                <PostalCodeInput
                                    controlId="postalCode"
                                    label="Postal Code"
                                    required={true}
                                    type="text"
                                    invalidFeedback="Must provide a postal code matching the A1A 1A1 pattern."
                                    value={postalCode}
                                    onChange={(e) => setPostalCode(e)}
                                    pattern="^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$"
                                />
                            </Row>
                            <Row>
                                <Form.Group class="form-group">
                                    <Button
                                        className="btn-standard margin-right-10"
                                        type="submit">Resend access code</Button>
                                </Form.Group>
                            </Row>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
};
