import React from "react";
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from "../../../shared/utils/api";
import FormControlInput from "../../../shared/components/FormControlInput";
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

function AuditQuestionOption() {
    const [header, setHeader] = useState('');
    const [option, setOption] = useState('');
    const [sortOrder, setSortOrder] = useState(0);

    const navigate = useNavigate();

    const params = useParams();

    const { questionID, optionID } = params;

    const fetchQuestionOption = () => {
        api.get('/audit-question-options/' + optionID).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setHeader(response.header);
                setOption(response.option);
                setSortOrder(response.sortOrder);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    useEffect(() => {
        fetchQuestionOption()
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        api.post('/audit-question-options', {
            auditQuestionID: parseInt(questionID),
            auditQuestionOptionID: parseInt(optionID),
            header: header,
            option: option,
            sortOrder: parseInt(sortOrder)
        }).then(
            () => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    };

    const handleDelete = (e) => {
        e.preventDefault();

        api.delete('/audit-question-options/' + optionID).then(
            () => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    return (
        <div>
            <form class="form-container" onSubmit={handleSubmit}>
                <h4>{option}</h4>
                <FormControlInput label="Header" type="text" value={header} onChange={(e) => setHeader(e.target.value)} />
                <FormControlInput label="Option" type="text" value={option} onChange={(e) => setOption(e.target.value)} />
                <FormControlInput label="Sort Order" type="number" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} />
                <button type="submit" className="btn-standard success">Save</button>
                <button type="button" className="btn-standard danger" onClick={handleDelete}>Delete</button>
            </form>
        </div>
    );
}

export default AuditQuestionOption;
