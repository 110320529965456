import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../../../shared/utils/api';
import FormControlInput from '../../../shared/components/FormControlInput';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

function AuditModule() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [moduleOrder, setModuleOrder] = useState(0);
    const [questions, setQuestions] = useState([]);

    const navigate = useNavigate();

    const params = useParams();
    //get the id passed in from the URL path
    const { id, moduleID } = params;

    //look up the course module by id
    const fetchAuditModule = () => {
        api.get('/audit-modules/' + moduleID).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setTitle(response.moduleTitle);
                setDescription(response.moduleDescription);
                setModuleOrder(response.sortOrder);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    const fetchAuditQuestions = () => {
        api.get('/audit-questions?moduleID=' + moduleID).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                //sort the pages by pageOrder
                response.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1)
                setQuestions(response);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }


    useEffect(() => {
        fetchAuditModule();
        fetchAuditQuestions();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        api.post('/audit-modules', {
            auditID: parseInt(id),
            auditModuleID: parseInt(moduleID),
            moduleTitle: title,
            moduleDescription: description,
            sortOrder: parseInt(moduleOrder)
        }).then(
            (response) => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    };

    const handleDelete = (e) => {
        e.preventDefault();

        api.delete('/audit-modules/' + moduleID).then(
            () => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    return (
        <div>
            <form class="form-container" onSubmit={handleSubmit}>
                <h4>{title}</h4>
                <FormControlInput label="Title" type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                <FormControlInput label="Description" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                <FormControlInput label="Module Order" type="number" value={moduleOrder} onChange={(e) => setModuleOrder(e.target.value)} />

                <div>
                    <h3>Questions</h3>
                    <ul>
                        {questions.map((question) => (
                            <li key={question.auditQuestionID}>
                                <Link to={"/audits/" + id + "/modules/" + moduleID + "/questions/" + question.auditQuestionID}>{question.question}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <button type="button" className="btn-standard" onClick={() => navigate("/audits/" + id + "/modules/" + moduleID + "/questions/add")}>New Question</button>
                <br />
                <br />
                <button type="submit" className="btn-standard success">Save</button>
                <button type="button" className="btn-standard danger" onClick={handleDelete}>Delete</button>
            </form>
        </div>
    );
}

export default AuditModule;
