import React from "react";

// TODO: don't store static media assets in the frontend
function SignUpComplete(props) {
    return (
        <div class="page-preview">
            <div class="flex justify-center align-items-center">

                <img src="/firesmart mascot.png" alt="FireSmart" />
                <div class="">
                    <h2 class="no-margin-top">Registration Complete!</h2>
                    <p>A FireSmart admin will review your account and grant access to you shortly.</p>
                    <button class="btn-standard" onClick={props.signUpCallback}>Go Back</button>
                </div>
            </div>
        </div>
    );
}

export default SignUpComplete;
