import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../shared/utils/api';
import FormControlInput from '../../../shared/components/FormControlInput';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

export default function AddAuditModule() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [sortOrder, setSortOrder] = useState(0);

    const navigate = useNavigate();

    const params = useParams();

    const { id } = params;

    const createModule = () => {
        //TODO: There is probably another way to stringify the form without listing out 
        //each item individually like this. Is that a thing and if so is it better?
        api.post('/audit-modules', {
            auditID: parseInt(id),
            moduleTitle: title,
            moduleDescription: description,
            sortOrder: parseInt(sortOrder)
        }).then(
            () => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        createModule();
    };

    return (
        <div>
            <h2>Add Assessment Module</h2>
            <form onSubmit={handleSubmit}>
                <FormControlInput label="Title" type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                <FormControlInput label="Description" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                <FormControlInput label="Module Order" type="number" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} />
                <br />
                <button type="submit" className="btn-submit">Save</button>
            </form>
        </div>
    );
};
