import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../shared/utils/api';
import FormControlInput from '../../shared/components/FormControlInput';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../shared/Constants';

export default function AddAudit() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const navigate = useNavigate();

    const addAudit = () => {
        api.post('/audits', {
            auditID: 0,
            auditTitle: title,
            auditDescription: description,
            certificationID: 1
        }).then(
            () => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        addAudit();

        setTitle('');
        setDescription('');
    }

    return (
        <form onSubmit={handleSubmit}>
            <h2>Add new Assessment</h2>
            <FormControlInput label="Assessment Title" type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
            <FormControlInput label="Assessment Description" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />

            <br />
            <br />
            <button type="submit" className="btn-submit">Save</button>
        </form>
    )
};
