import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../shared/utils/api';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

export default function AddCoursePage() {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [pageOrder, setPageOrder] = useState(0);
    const [mediaLink, setMediaLink] = useState('');

    const navigate = useNavigate();

    const params = useParams();

    const { id, moduleID } = params;

    const handleSubmit = (e) => {
        e.preventDefault();

        api.post('/pages', { courseID: parseInt(id), courseModuleID: parseInt(moduleID), pageTitle: title, pageContent: content, pageOrder: parseInt(pageOrder), pageMediaLink: mediaLink }).then(
            (response) => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    };

    return (
        <div>
            <h2>Add Course Page</h2>
            <form onSubmit={handleSubmit}>
                <label htmlFor="pageTitle">Page Title</label>
                <br />
                <input
                    type="text"
                    name="pageTitle"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)} />
                <br />
                <label htmlFor="pageContent">Page Content</label>
                <br />
                <textarea
                    value={content}
                    name="pageContent"
                    onChange={(e) => setContent(e.target.value)} />
                <br />
                <label htmlFor="pageOrder">Page Order</label>
                <br />
                <input
                    type="number"
                    value={pageOrder}
                    name="pageOrder"
                    onChange={(e) => setPageOrder(e.target.value)} />
                <br />
                <label htmlFor="pageMediaLink">Page Media URL</label>
                <br />
                <input
                    type="text"
                    value={mediaLink}
                    name="pageMediaLink"
                    onChange={(e) => setMediaLink(e.target.value)} />
                <br />
                <br />
                <button type="submit" className="btn-submit">Save</button>
            </form>
        </div>
    );
};
