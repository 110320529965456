import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../../../shared/utils/api';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

function CourseModule() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [moduleOrder, setModuleOrder] = useState(0);
    const [pages, setPages] = useState([]);
    const [quizID, setQuizID] = useState(0);
    const [quizzes, setQuizzes] = useState([{}]);
    const [quizQuestions, setQuizQuestions] = useState([]);

    const navigate = useNavigate();

    const params = useParams();
    //get the id passed in from the URL path
    const { id, moduleID } = params;

    //look up the course module by id
    const fetchCourseModule = () => {
        api.get('/modules/' + moduleID).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setTitle(response.moduleTitle);
                setDescription(response.moduleDescription);
                setModuleOrder(response.moduleOrder);
                setQuizID(response.quizID);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    const fetchCoursePages = () => {

        api.get('/pages?moduleID=' + moduleID).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                //sort the pages by pageOrder
                response.sort((a, b) => (a.pageOrder > b.pageOrder) ? 1 : -1)
                setPages(response);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    const fetchQuizzes = () => {
        api.get('/quizzes').then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setQuizzes(response);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    const fetchQuizQuestions = () => {
        if (quizID === 0) {
            setQuizQuestions([]);
            return;
        }

        api.get('/quiz-questions?quizID=' + quizID).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                //sort the questions by questionOrder
                response.sort((a, b) => (a.questionOrder > b.questionOrder) ? 1 : -1)
                setQuizQuestions(response);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    useEffect(() => {
        fetchCourseModule();
        fetchCoursePages();
        fetchQuizzes();
    }, []);

    useEffect(() => {
        fetchQuizQuestions();
    }, [quizID]);

    const handleSubmit = (e) => {
        e.preventDefault();

        api.post('/modules', {
            courseID: parseInt(id),
            courseModuleID: parseInt(moduleID),
            moduleTitle: title,
            moduleDescription: description,
            moduleOrder: parseInt(moduleOrder),
            quizID: parseInt(quizID)
        }).then(
            (response) => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    };

    const handleDelete = (e) => {
        e.preventDefault();

        api.delete('/modules/' + moduleID).then(
            (response) => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <h2>{title}</h2>
                <div className="input-container">
                    <label htmlFor="moduleTitle">Title</label>

                    <br />
                    <input
                        name="moduleTitle"
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="moduleDescription">Description</label>
                    <br />
                    <textarea
                        name="moduleDescription"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}>
                    </textarea>
                </div>
                <div className="input-container">
                    <label htmlFor="moduleOrder">Module Order</label>
                    <br />
                    <input
                        name="moduleOrder"
                        type="number"
                        value={moduleOrder}
                        onChange={(e) => setModuleOrder(e.target.value)}
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="quizID">Quiz</label>
                    <br />
                    <select
                        name="quizID"
                        value={quizID}
                        onChange={(e) => setQuizID(e.target.value)}>
                        <option value="0">None</option>
                        {quizzes.map((quiz) => (
                            <option key={quiz.quizID} value={quiz.quizID}>{quiz.quizTitle}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <h3>Pages</h3>
                    <ul>
                        {pages.map((page) => (
                            <li key={page.coursePageID}>
                                <Link to={"/courses/" + id + "/modules/" + moduleID + "/pages/" + page.coursePageID}>{page.pageTitle}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <button type="button" className="btn-new-page" onClick={() => navigate("/courses/" + id + "/modules/" + moduleID + "/pages/add")}>New Page</button>
                <br />
                <button
                    type="button"
                    className="btn-preview"
                    onClick={() => navigate("/courses/" + id + "/modules/" + moduleID + "/preview", { state: { pages } })}>Preview Course</button>
                <br />
                <button
                    type="button"
                    className="btn-preview"
                    visible={quizID === 0 ? false : true}
                    onClick={() => navigate(`/quizzes/${id}/questions/preview`, { state: { quizQuestions } })}>Preview Quiz</button>
                <br />
                <br />
                <button type="submit" className="btn-submit">Save</button>
                <button type="button" className="btn-delete" onClick={handleDelete}>Delete</button>
            </form>
        </div>
    );
}

export default CourseModule;
