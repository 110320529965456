import React from "react";

function RegistrationSubmitted(props) {
    return (
        <div class="page-preview">
            <div class="flex justify-center align-items-center">

                <img src="/firesmart mascot.png" alt="FireSmart" />
                <div class="">
                    <h2 class="no-margin-top">Thank You!</h2>
                    <p>One of our FireSmart Admins will review your application and get back to you shortly.</p>
                    <button class="btn-standard" onClick={props.backCallback}>Back</button>
                </div>
            </div>
        </div>
    );
}

export default RegistrationSubmitted;
