import { useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import api from '../../../shared/utils/api';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

export default function AddCourseModule() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [moduleOrder, setModuleOrder] = useState(0);

    const navigate = useNavigate();

    const params = useParams();

    const { id } = params;

    const createModule = () => {
        //TODO: There is probably another way to stringify the form without listing out 
        //each item individually like this. Is that a thing and if so is it better?
        api.post('/modules', { courseID: parseInt(id), moduleTitle: title, moduleDescription: description, moduleOrder: parseInt(moduleOrder) }).then(
            (response) => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        createModule();
    };

    return (
        <div>
            <h2>Add Course Module</h2>
            <form onSubmit={handleSubmit}>
            <div className="input-container">
                    <label htmlFor="moduleTitle">Title</label>

                    <br />
                    <input
                        name="moduleTitle"
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="moduleDescription">Description</label>
                    <br />
                    <textarea
                        name="moduleDescription"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}>
                    </textarea>
                </div>
                <div className="input-container">
                    <label htmlFor="moduleOrder">Module Order</label>
                    <br />
                    <input
                        name="moduleOrder"
                        type="number"
                        value={moduleOrder}
                        onChange={(e) => setModuleOrder(e.target.value)}
                    />
                </div>
                <br />
                <br />
            <button type="submit" className="btn-submit">Save</button>
            </form>
        </div>
    );
};
