import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';

// TODO: I'm not a designer, this page needs some love but this is a start
export default function HomeInspectionPublicView() {
    const [homeInspection, setHomeInspection] = useState(null);

    const navigate = useNavigate();

    const { state } = useLocation();

    useEffect(() => {
        if (!state || state === null || state === undefined) {
            navigate('/view-home-inspection');
            return
        }

        setHomeInspection(state.homeInspection);
    }, []);

    if (!homeInspection) {
        return (
            <div>
                <h4>Loading...</h4>
            </div>
        )
    }

    const handleRetrieveAnotherHomeInspection = (e) => {
        e.preventDefault();
        navigate('/view-home-inspection');
    }

    const handleViewAudit = (e) => {
        e.preventDefault();
        navigate('/view-home-inspection/inspection/' + homeInspection.homeInspectionID,
            {
                state:
                {
                    auditID: homeInspection.auditID,
                    homeInspectionID: homeInspection.homeInspectionID,
                    accessCode: homeInspection.homeInspectionAccessCode,
                    userEmail: homeInspection.homeOwnerEmailAddress
                }
            }
        );
    }

    const inspectorAssigned = homeInspection.assignedInspectorID !== null;

    const completedStatus = ["Completed", "Changes Requested", "Changes Submitted"]
    const canViewAudit = completedStatus.includes(homeInspection.homeInspectionStatus);

    // If the home inspection is cancelled, show the cancellation note over the assignedInspector note
    const notes = homeInspection.CancellationNote || homeInspection.assignedInspectorNote || "";

    return (
        <div>
            <div class="form-container">
                <h4>Home Assessment Request</h4>
                <Row>
                    <Col>
                        <label class="font-weight-bold">Contact Info</label>
                        <p>{homeInspection.homeOwnerPhoneNumber}</p>
                        <p>{homeInspection.homeOwnerEmailAddress}</p>
                    </Col>
                    <Col>
                        <label class="font-weight-bold">Home Assessment Status</label>
                        <p>{homeInspection.homeInspectionStatus}</p>

                    </Col>
                    {
                        inspectorAssigned &&
                        <Col>
                            <label class="font-weight-bold">Inspector</label>
                            <p>{homeInspection.assignedInspectorFirstName + " " +
                                homeInspection.assignedInspectorLastName}</p>
                        </Col>
                    }
                </Row>
                <Row>
                    <Col>
                        <label class="font-weight-bold">Address</label>
                        <p>{homeInspection.address}, {homeInspection.city}, {homeInspection.province}, {homeInspection.postalCode}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label class="font-weight-bold">Notes</label>
                        <textarea class="form-control" rows="3"
                            value={notes} disabled={true}></textarea>
                    </Col>
                </Row>

                <div class="form-group">
                    <button className="btn-standard margin-right-10"
                        type="submit"
                        onClick={handleRetrieveAnotherHomeInspection}>
                        Go Back
                    </button>
                    {
                        canViewAudit &&
                        <button className="btn-standard"
                            type="submit"
                            onClick={handleViewAudit}>
                            View Assessment
                        </button>
                    }
                </div>
            </div>
        </div>
    )
};
