import React from "react";
import { useState } from 'react';
import api from '../shared/utils/api';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../shared/Constants';

function ApprovalRequest({ certEntitlement, refreshCallback }) {
    const [deniedNote, setDeniedNote] = useState('');
    const [showDeny, setShowDeny] = useState(false);

    const handleApprove = (e) => {
        e.preventDefault();
        console.log('Approve');
        api.post('/certification-entitlements/completion/approve/' + certEntitlement.entitlementID)
            .then(
                (response) => {
                    refreshCallback();
                },
                (error) => {
                    toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
                });
    }

    const handleDeny = (e) => {
        e.preventDefault();
        setShowDeny(true);
    }

    const handleCancel = (e) => {
        e.preventDefault();
        setShowDeny(false);
    }

    const submitDeny = (e) => {
        e.preventDefault();
        api.post('/certification-entitlements/completion/deny', {
            entitlementID: parseInt(certEntitlement.entitlementID),
            deniedNote: deniedNote
        }).then(
            (response) => {
                refreshCallback();
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    const mainContent = () => {
        if (showDeny) {
            return (
                <form class="form-container">
                    <h4>Deny Certification</h4>
                    <div class="mb-3">
                        <label for="deniedNote" class="form-label">Reason for Denial</label>
                        <textarea class="form-control" id="deniedNote"
                            rows="3" onChange={(e) => setDeniedNote(e.target.value)}></textarea>
                    </div>
                    <button className="btn-standard success margin-right-10" type="submit"
                        onClick={submitDeny}>Submit</button>
                    <button className="btn-standard danger" type="submit"
                        onClick={handleCancel}>Cancel</button>
                </form>
            );
        }

        return (
            <form class="form-container">
                <h4>Pending Certification Approval</h4>
                <button className="btn-standard success margin-right-10" type="submit"
                    onClick={handleApprove}>Approve</button>
                <button className="btn-standard danger" type="submit"
                    onClick={handleDeny}>Deny</button>
            </form>
        );
    }

    return (
        <div>
            {mainContent()}
        </div>
    );
}

export default ApprovalRequest;
