import React from 'react';
import { useState } from 'react';
import MultiSelectOptions from '../Form/MultiSelectOptions';
import CheckBox from '../../../shared/components/CheckBox';
import ImageContainer from '../Form/ImageContainer';
import ImageFull from './ImageFull';
import api from '../../../shared/utils/api';
import { toast } from 'react-toastify';
import HomeInspectionFileUpload from '../../HomeInspectionFileUpload';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

function MediaPreview({ media }) {
    if (media === null) {
        return null;
    }
    else {
        return (
            <>
                <label class="form-label font-weight-bold">Preview</label>
                <img class="audit-image-full" src={media} />
            </>
        );
    }
}

function HomeOwnerQuestion({
    auditID,
    homeInspectionID,
    accessCode,
    userEmail,
    questionID,
    questionContent,
    questionAnswers,
    inspectorComment,
    homeOwnerComment,
    images,
    requiresActionValue,
    setCommentCallback
}) {
    const [selectedOptions] = useState(questionAnswers
        ?.filter((a) => a.selected).map((a) => a.auditQuestionOptionID));
    const [requiresAction, setRequiresAction] = useState(requiresActionValue);
    const [questionComment, setQuestionComment] = useState(homeOwnerComment);
    const [imageAttachments, setImageAttachments] = useState(images || []);
    const [previewImageData, setPreviewImageData] = useState(null);
    const [file, setFile] = useState(null);

    function groupByHeaderAdvanced(xs, key) {
        if (!xs) return [];
        return xs.reduce(
            function (rv, x) {
                let v = key instanceof Function ? key(x) : x[key];
                let el = rv.find((r) => r && r.values.header === v);

                if (el && v !== '') {
                    el.values.options.push(x);
                }
                else {
                    rv.push({ key: rv.length, values: { header: v, options: [x] } });
                }

                return rv;
            }, []);
    }

    const groupedAnswers = groupByHeaderAdvanced(questionAnswers, 'header')
        .sort((a, b) => (a.values.options[0].sortOrder > b.values.options[0].sortOrder) ? 1 : -1);

    const handleCommentChange = (e) => {
        setQuestionComment(e.target.value);
    }

    const handleSaveComment = () => {
        setCommentCallback(questionID, questionComment);
    }

    const handleFileChange = (event) => {
        // TODO: didn't have time to support multiple files, but should work with some tweaks
        // example: https://jsfiddle.net/41go76g4/

        if (event.target.files[0].size > 100000000) {
            toast.error('File size must be less than 100MB', { autoClose: ERROR_MESSAGE_DURATION });
            setFile(null);
        }

        if (!event.target.files[0].type.match('image/*')) {
            toast.error('File must be an image', { autoClose: ERROR_MESSAGE_DURATION });
            setFile(null);
        }

        var reader = new FileReader();

        // Closure to capture the file information.
        reader.onload = (function () {
            return function (e) {
                setPreviewImageData(e.target.result);
            };
        })(file);

        // Read in the image file as a data URL.
        reader.readAsDataURL(event.target.files[0]);

        setFile(event.target.files[0]);
    };

    const handleFileSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('file', file);
        formData.append('auditID', auditID);
        formData.append('auditQuestionID', questionID);
        formData.append('homeInspectionID', homeInspectionID);
        formData.append('accessCode', accessCode);
        formData.append('userEmail', userEmail);

        api.postFormData('/homeowner/upload-home-inspection-image', formData).then(
            (response) => {
                imageUploadSuccess(response);
                toast.success('File uploaded successfully', { autoClose: 2000 });
                setFile(null);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    };

    const addImage = (image) => {
        setImageAttachments([...imageAttachments, image]);
    }

    const handleFileCancel = (e) => {
        setFile(null);
        setPreviewImageData(null);
        //get parent form and reset it
        var form = e.target.form;
        form.reset();
    };

    const imageUploadSuccess = async (response) => {
        addImage({ imageID: response.homeInspectionImageID, imageURL: response.presignedURL });
        setPreviewImageData(null);
    };

    return (
        <div>
            <div class="audit-question-container" key={questionID}>
                <h4>
                    <a name={questionContent}>{questionContent}</a>
                </h4>
                <div class="row gy-2 gx-1">
                    <div class="audit-options-container flex col-gap-20">
                        {groupedAnswers.map((group) => (
                            <MultiSelectOptions
                                questionID={questionID}
                                header={group.values.header}
                                options={group.values.options}
                                selectedOptions={selectedOptions}
                                disabled={true}
                            />
                        ))}
                    </div>
                    <div class="form-group">
                        <label for="comment" class="form-label font-weight-bold">Inspector Comment</label>
                        <textarea
                            class="form-control"
                            rows="3"
                            value={inspectorComment}
                            disabled></textarea>
                    </div>
                    {requiresAction && <div class="form-group">
                        <CheckBox
                            label="Requires Action"
                            bold
                            disabled
                            checked={requiresAction} />
                    </div>}
                    {(requiresAction || questionComment) &&
                        <div class="form-group">
                            <label for="comment" class="form-label font-weight-bold">My Comment</label>
                            <textarea
                                class="form-control"
                                rows="3"
                                value={questionComment}
                                disabled={!requiresAction}
                                onChange={handleCommentChange}></textarea>
                        </div>}
                    {requiresActionValue && <div class="form-group no-print">
                        <button class="btn btn-standard" onClick={handleSaveComment}>Save</button>
                    </div>}
                    {requiresAction && <HomeInspectionFileUpload
                        handleFileCancel={handleFileCancel}
                        handleFileChange={handleFileChange}
                        handleFileSubmit={handleFileSubmit}
                        file={file}
                    />}
                    {previewImageData && <MediaPreview media={previewImageData} />}
                    <ImageContainer>
                        {imageAttachments.map((image) => (
                            <ImageFull imageURL={image.imageURL} />
                        ))}
                    </ImageContainer>
                </div>
            </div>
        </div>
    );
}

export default HomeOwnerQuestion;
