import React from "react";

function CourseComplete(props) {
    return (
        <div class="page-preview">
            <div class="row">
                <div class="col-2"></div>
                <div class="col">
                    <img class="center-img" src="/firesmart mascot.png" alt="FireSmart" />
                </div>
                <div class="col center-div">
                    <h2 class="no-margin-top">Congratulations!</h2>
                    <p>You have completed all of the modules in this course!
                        A FireSmart admin will review your progress before granting your certificate.
                        Click the button below to continue.</p>
                    <button class="btn-standard" onClick={props.courseCompleteCallback}>Continue</button>
                </div>
                <div class="col-2"></div>
            </div>
        </div>
    );
}

export default CourseComplete;
