import React, { useEffect, useRef } from "react";
import { useState } from "react";
import api from "../shared/utils/api";
import { useNavigate } from "react-router-dom";
import FormGroupProvinceDropdownInput from "../shared/components/FormGroupProvinceDropdownInput";
import { toast } from "react-toastify";
import ChangePassword from "./ChangePassword";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import FormGroupInput from "../shared/components/FormGroupInput";
import TokenRefresh from "./TokenRefresh";
import { ERROR_MESSAGE_DURATION } from "../shared/Constants";
import PostalCodeInput from "../shared/components/PostalCodeInput";
import RoleDropdown from "./RoleDropdown";

function UserProfile() {
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    const [userID, setUserID] = useState(0);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [province, setProvince] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [userRoleId, setUserRoleId] = useState();

    const toastID = useRef(null);

    const fetchUser = () => {
        api.get("/user-profile").then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setUserID(response.userID);
                setFirstName(response.firstName);
                setLastName(response.lastName);
                setEmail(response.email);
                setPhoneNumber(response.phoneNumber);
                setAddress(response.address);
                setCity(response.city);
                setProvince(response.province);
                setPostalCode(response.postalCode);
                setUserRoleId(response.userRoleID);

                //TODO: This is kinda hacky, basically evertime you go to this page it will
                // check if the form is validor not and show red Xs or green checks
                setValidated(true);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        toastID.current = toast("Saving...", { autoClose: false });

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
            toast.update(toastID.current, {
                render: "Failed to updated profile. Please check all required form fields",
                type: "error",
                autoClose: ERROR_MESSAGE_DURATION,
            });
            return;
        }

        setValidated(true);

        api.post("/user-profile", {
            userID: parseInt(userID),
            firstName: firstName,
            lastName: lastName,
            email: email,
            // TODO: input sanitization
            phoneNumber: phoneNumber.startsWith("+1")
                ? phoneNumber.replace(/[\(\)-]/g, "")
                : "+1" + phoneNumber.replace(/[\(\)-]/g, ""),
            address: address,
            city: city,
            province: province,
            postalCode: postalCode,
            userRoleId: +userRoleId
        }).then(
            (response) => {
                toast.update(toastID.current, {
                    render: "User Profile Saved!",
                    type: "success",
                    autoClose: 3000,
                });
                // Update the user's token in case they change their name.
                // TODO: this won't update the header unless a hard reset is done.
                TokenRefresh();
            },
            (error) => {
                toast.update(toastID.current, {
                    render: error.message,
                    type: "error",
                    autoClose: ERROR_MESSAGE_DURATION,
                });
            }
        );
    };

    const handleCancel = (e) => {
        e.preventDefault();
        navigate(-1);
    };

    const handleChangePasswordClick = (e) => {
        e.preventDefault();
        setShowChangePassword(true);
    };

    useEffect(() => {
        fetchUser();
    }, []);

    const mainContent = () => {
        if (showChangePassword) {
            return (
                <ChangePassword
                    email={email}
                    cancelCallback={() => setShowChangePassword(false)}
                    successCallback={() => setShowChangePassword(false)}
                />
            );
        }

        return (
            <div>
                <div class="form-container">
                    <h4>User Profile</h4>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <Row>
                            <FormGroupInput
                                label="First Name"
                                name="firstName"
                                type="text"
                                value={firstName}
                                placeholder="First Name"
                                onChange={(e) => setFirstName(e.target.value)}
                                required={true}
                            />
                            <FormGroupInput
                                label="Last Name"
                                name="lastName"
                                type="text"
                                value={lastName}
                                placeholder="Last Name"
                                onChange={(e) => setLastName(e.target.value)}
                                required={true}
                            />
                        </Row>
                        <Row>
                            <FormGroupInput
                                label="Email"
                                name="email"
                                type="text"
                                value={email}
                                disabled={true}
                            />
                        </Row>
                        <Row>
                            <Form.Group controlId="password" class="form-group">
                                <Form.Label>Password</Form.Label>
                                <div class="input-group">
                                    <Form.Control
                                        type="password"
                                        value="no peeking this isn't a real password"
                                        disabled="true"
                                    />
                                    <Button
                                        className="btn-standard"
                                        type="button"
                                        onClick={handleChangePasswordClick}
                                    >
                                        Change Password
                                    </Button>
                                </div>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group controlId="userRole" class="form-group">
                                <RoleDropdown selectedRole={userRoleId} setSelectedRole={(e) => setUserRoleId(e)} disabled={true} />
                            </Form.Group>
                        </Row>
                        <Row>
                            <FormGroupInput
                                label="Phone Number"
                                name="phoneNumber"
                                type="text"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                required={true}
                                pattern="^(\+1)?(\(?\d{3}\)?-?\d{3}-?\d{4})|(\d{10})"
                                invalidFeedback="Phone number must be in the format 123-456-7890 or +11234567890."
                            />
                        </Row>
                        <Row>
                            <FormGroupInput
                                label="Address"
                                name="address"
                                type="text"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                required={true}
                                invalidFeedback="Must provide an address with allowed special characters .;:, and allowed spaces."
                                pattern="^[a-zA-Z0-9 .:,-_']+$"
                            />
                        </Row>
                        <Row>
                            <FormGroupInput
                                label="City"
                                name="city"
                                type="text"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required={true}
                                invalidFeedback="Must provide a city with allowed special characters .:-_', and allowed spaces."
                                pattern="^[a-zA-Z0-9 .:,-_']+$"
                            />
                        </Row>
                        <Row>
                            <FormGroupProvinceDropdownInput
                                value={province}
                                required={true}
                                onChange={(e) => setProvince(e.target.value)}
                                placeHolderRequired={false}
                            />
                        </Row>
                        <Row>
                        <PostalCodeInput
                            controlId="postalCode"
                            label="Postal Code"
                            required={true}
                            type="text"
                            invalidFeedback="Must provide a postal code matching the A1A 1A1 pattern."
                            value={postalCode}
                            onChange={(e) => setPostalCode(e)}
                            pattern="^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$"
                        />
                        </Row>
                        <Row>
                            <Form.Group class="form-group">
                                <Button
                                    className="btn-standard margin-right-10"
                                    type="submit"
                                >
                                    Update
                                </Button>
                                <Button
                                    className="btn-standard danger"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
            </div>
        );
    };

    return mainContent();
}

export default UserProfile;
