import React, { useEffect } from "react";
import { useState } from "react";

function QuizResult(props) {
    const [quizPassed, setQuizPassed] = useState(false);

    useEffect(() => {
        if (props.score === 100) {
            setQuizPassed(true);
        }
    }, [props.score]);

    return (
        <div class="page-preview">
            <div class="flex justify-center align-items-center">

                <img src="/firesmart mascot.png" alt="FireSmart" />
                <div class="">
                    <h2 class="no-margin-top">Quiz Complete!</h2>
                    {quizPassed && <p>Congratulations! You have passed the quiz.</p>}
                    <p>Your score is <span class="font-weight-bold">{props.score}%</span></p>
                    {!quizPassed && <p>You have not passed the quiz. Please try again.</p>}
                    {quizPassed && <button class="btn-standard" onClick={props.moduleCompleteCallback}>Continue</button>}
                    {!quizPassed && <button class="btn-standard margin-right-10" onClick={props.retryQuizCallback}>Retry Quiz</button>}
                    {!quizPassed && <button class="btn-standard" onClick={props.restartCourseModuleCallback}>Restart Module</button>}
                </div>
            </div>
        </div>);
}

export default QuizResult;
