import React from "react";
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import FileUpload from "../../FileUpload";
import api from "../../../shared/utils/api";
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

function MediaPreview({ media }) {
    if (media === null) {
        return (<p>No media to display</p>);
    }
    else {
        return (
            <img src={media} />
        );
    }
}

function CoursePage() {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [pageOrder, setPageOrder] = useState(0);
    const [pageMediaKey, setPageMediaKey] = useState('');
    const [imageData, setImageData] = useState('');

    const navigate = useNavigate();

    const params = useParams();

    const { id, moduleID, pageID } = params;

    const fetchCoursePage = () => {
        api.get('/pages/' + pageID).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setTitle(response.pageTitle);
                setContent(response.pageContent);
                setPageOrder(response.pageOrder);
                setPageMediaKey(response.pageMediaKey);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    useEffect(() => {
        fetchCoursePage()
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        //TODO: There is probably another way to stringify the form without listing out 
        //each item individually like this. Is that a thing and if so is it better?

        api.post('/pages', {
            coursePageID: parseInt(pageID),
            courseModuleID: parseInt(moduleID),
            pageTitle: title,
            pageContent: content,
            pageOrder: parseInt(pageOrder),
            pageMediaKey: pageMediaKey
        }).then(
            (response) => {
                toast.success('Page saved successfully', { autoClose: 2000 });
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    };

    const handleDelete = (e) => {
        e.preventDefault();

        api.delete('/pages/' + pageID).then(
            (response) => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    return (
        <div>
            <h2>{title}</h2>
            <form onSubmit={handleSubmit}>
                <label htmlFor="pageTitle">Page Title</label>
                <br />
                <input
                    type="text"
                    name="pageTitle"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)} />
                <br />
                <label htmlFor="pageContent">Page Content</label>
                <br />
                <textarea
                    value={content}
                    name="pageContent"
                    onChange={(e) => setContent(e.target.value)} />
                <br />
                <label htmlFor="pageOrder">Page Order</label>
                <br />
                <input
                    type="number"
                    value={pageOrder}
                    name="pageOrder"
                    onChange={(e) => setPageOrder(e.target.value)} />
                <br />
                <br />
                <label htmlFor="pageMediaLink">Page Media</label>
                <br />
                <FileUpload
                    uploadCallback={setImageData}
                    courseID={id}
                    courseModuleID={moduleID}
                    pageID={pageID}
                />
                <input
                    type="hidden"
                    value={imageData}
                    name="imageData"
                    onChange={(e) => setImageData(e.target.value)} />
                <label>Media Preview</label>
                <br />
                <MediaPreview media={imageData} />
                <br />
                <br />
                <button type="submit" className="btn-submit">Save</button>
                <button type="button" className="btn-delete" onClick={handleDelete}>Delete</button>
            </form>
        </div>
    );
}

export default CoursePage;
