import React, { useState, useEffect } from 'react';
import api from '../../../shared/utils/api';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

function QuizQuestionPreview(props) {
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [showAnswer, setShowAnswer] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
    const { question, finalQuestion } = props;

    const resetSelectedAnswers = () => {
        setSelectedAnswers([]);
        setShowAnswer(false);
    }

    const submitQuestionAttempt = () => {
        let checkedBoxes = document.querySelectorAll('input[type="checkbox"]:checked');

        if (checkedBoxes.length === 0) {
            toast.error("Please select at least one answer", { autoClose: ERROR_MESSAGE_DURATION });
            return;
        }

        api.post('/question-attempts/submit', {
            QuizAttemptID: parseInt(props.quizAttemptID),
            QuizQuestionID: parseInt(question.quizQuestionID),
            QuestionAnswerIDs: selectedAnswers
        }).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;

                setIsAnswerCorrect(response.isCorrect);

                setShowAnswer(true);

                props.correctAnswerCallback(response.isCorrect);
            },
            () => {
                toast.error("Error submitting answer", { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    };

    useEffect(() => {
        const fetchAnswers = () => {
            api.get('/quiz-answers?questionID=' + question.quizQuestionID).then(
                (response) => {
                    if (response === null) return;
                    if (response === undefined) return;
                    //sort the answers by answerOrder
                    response.sort((a, b) => (a.answerOrder > b.answerOrder) ? 1 : -1)
                    setAnswers(response);
                },
                (error) => {
                    toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
                }
            );
        }

        fetchAnswers();
        resetSelectedAnswers();
    }, [question.quizQuestionID]);

    const handleListClick = (answer) => {
        answerChange(answer);
    }

    const answerChange = (answer) => {
        if (selectedAnswers.includes(answer)) {
            setSelectedAnswers(selectedAnswers.filter((a) => parseInt(a) !== answer));
        }

        if (!selectedAnswers.includes(answer)) {
            setSelectedAnswers([...selectedAnswers, parseInt(answer)]);
        }
    };

    const handleNextClick = () => {
        if (showAnswer) {
            props.nextQuestionCallback();
        }
    }

    function NextButton() {
        if (finalQuestion) {
            return <button class="btn-standard" onClick={props.submitQuizCallback}>Complete Quiz</button>;
        }

        return <button class="btn-standard" onClick={handleNextClick}>Next Question</button>;
    }

    return (
        <div class="quiz-question">
            <div class="form-container">
                <h4>{question.questionContent}</h4>
                <ul>
                    {answers.map((answer, index) => (
                        <li key={index} onClick={() => handleListClick(parseInt(answer.quizAnswerID))}>
                            <input
                                type="checkbox"
                                name={"answer" + index}
                                value={parseInt(answer.quizAnswerID)}
                                checked={selectedAnswers.includes(answer.quizAnswerID)}
                            />
                            <label>
                                {answer.answerContent}
                            </label>
                        </li>
                    ))}
                </ul>
                {!showAnswer && <button class="btn-standard" onClick={submitQuestionAttempt}>Submit Answer</button>}
                {showAnswer && (
                    <div>
                        <NextButton />
                    </div>
                )}
            </div>
        </div>
    );
}

export default QuizQuestionPreview;
