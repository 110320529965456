import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavLinkAuth({ to, authorized, children, ...props }) {
    if (authorized) {
        return (
            <>
                <li className="nav-item mb-2" {...props}>
                    <NavLink to={to} 
                    className={({ isActive }) => 
                        `nav-link text-black px-3 py-2 !text-base xl:!text-lg rounded-md ${isActive ? 'bg-gray-200' : 'hover:bg-gray-200'}`
                    }>{children}</NavLink>
                </li>
            </>
        );
    }
    else {
        return null;
    }
}
