import React from "react";

function CourseModuleComplete(props) {
    return (
        <div class="page-preview">
            <div class="flex justify-center align-items-center">

                <img src="/firesmart mascot.png" alt="FireSmart" />
                <div class="">
                    <h2 class="no-margin-top">Congratulations!</h2>
                    <p>You have completed this module. Click the button below to continue.</p>
                    <button class="btn-standard" onClick={props.nextModuleCallback}>Continue</button>
                </div>
            </div>
        </div>
    );
}

export default CourseModuleComplete;
