import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import api from '../../../shared/utils/api';
import _ from 'lodash';
import { toast } from 'react-toastify';
import HomeOwnerModule from './HomeOwnerModule';
import { Row, Col } from 'react-bootstrap';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

function HomeOwnerAuditForm() {
    const [audit, setAudit] = useState({});
    const [auditID, setAuditID] = useState(0); // TODO;
    const [accessCode, setAccessCode] = useState(''); // TODO: This will be needed to semi authenticate the user when updating comments
    const [userEmail, setUserEmail] = useState(''); // TODO: This will be needed to semi authenticate the user when updating comments

    const navigate = useNavigate();
    const params = useParams();

    const { id: homeInspectionID } = params;

    let location = useLocation();
    var state = location.state;

    const setupAuditFormData = (auditForm, auditResults) => {
        return {
            auditID: auditForm.auditID,
            auditTitle: auditForm.auditTitle,
            auditModules: auditForm.auditModules.map((module) => {
                return {
                    moduleTitle: module.moduleTitle,
                    auditQuestions: module.auditQuestions?.map((auditQuestion) => {
                        return {
                            auditQuestionID: auditQuestion.auditQuestionID,
                            question: auditQuestion.question,
                            auditQuestionOptions: auditQuestion.auditQuestionOptions?.map((answer) => {
                                return {
                                    auditQuestionOptionID: answer.auditQuestionOptionID,
                                    header: answer.header,
                                    option: answer.option,
                                    sortOrder: answer.sortOrder,
                                    selected: _.includes(auditResults.questionOptionIDs, answer.auditQuestionOptionID)
                                };
                            }),
                            defaultComment: auditQuestion?.defaultComment,
                            comment: _.find(auditResults.comments, (comment) => comment.questionID === auditQuestion.auditQuestionID)?.comment || auditQuestion.defaultComment || '',
                            homeOwnerComment: _.find(auditResults.comments, (comment) => comment.questionID === auditQuestion.auditQuestionID)?.homeOwnerComment,
                            supervisorComment: _.find(auditResults.comments, (comment) => comment.questionID === auditQuestion.auditQuestionID)?.supervisorComment,
                            supervisorCommentStatus: _.find(auditResults.comments, (comment) => comment.questionID === auditQuestion.auditQuestionID)?.supervisorCommentStatus,
                            requiresAction: _.find(auditResults.comments, (comment) => comment.questionID === auditQuestion.auditQuestionID)?.requiresAction || false,
                            images: _.filter(auditResults.images, (image) => image.questionID === auditQuestion.auditQuestionID) || []
                        };
                    })
                };
            })
        }
    };

    const setCommentCallback = (questionID, comment) => {
        api.post('/home-inspection-comments/home-owner', {
            inspectionAccessCode: accessCode,
            homeOwnerEmailAddress: userEmail,
            homeInspectionID: parseInt(homeInspectionID),
            auditQuestionID: questionID,
            homeOwnerComment: comment
        }).then(
            (response) => {
                toast.success('Comment saved successfully', { autoClose: 2000 });
            },
            (error) => {
                toast.error('Error saving comment', error, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    const changesRequestedItems = _.flatten(audit.auditModules?.map((module) => {
        return module.auditQuestions?.filter((question) => question.requiresAction)
    })).filter((question) => question !== undefined);

    const handleChangeItemClick = (e) => {
        let a = e.target.querySelector('a');
        if (a) {
            a.click();
        }
    }

    useEffect(() => {
        if (auditID === 0) return;
        if (homeInspectionID === 0) return;

        const promiseAuditInfo = new Promise((resolve, reject) => {
            api.get('/homeowner/audit-form/' + auditID + '?accessCode=' + accessCode +
                '&email=' + userEmail).then(
                    (response) => {
                        if (response) {
                            resolve(response);
                        }
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });

        const promiseAuditResults = new Promise((resolve, reject) => {
            api.get('/homeowner/home-inspection-results/' + homeInspectionID + '?accessCode=' +
                accessCode + '&email=' + userEmail).then(
                    (response) => {
                        if (response) {
                            resolve(response);
                        }
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });

        const fetchData = () => {
            Promise.all([promiseAuditInfo, promiseAuditResults]).then((values) => {
                var auditInfo = values[0];
                var auditResults = values[1];

                var tempAudit = setupAuditFormData(auditInfo, auditResults);
                setAudit(tempAudit);
            });
        };

        fetchData();
    }, [auditID, homeInspectionID]);

    useEffect(() => {
        if (state) {
            setAuditID(state.auditID);
            setAccessCode(state.accessCode);
            setUserEmail(state.userEmail);
        }

        if (!state) {
            // TODO: Something went wrong or the use navigated directly to this link and not from
            // the view inspection page so state isn't set. If that's the case then we should redirect
            navigate('/view-home-inspection');
        }
    }, []);

    return (
        <div>
            <h2 class="font-weight-bold">{audit.auditTitle}</h2>
            <Row>
                <Col md={9}>
                    {audit.auditModules && audit.auditModules.map((module) => (
                        <HomeOwnerModule
                            auditID={audit.auditID}
                            homeInspectionID={homeInspectionID}
                            accessCode={accessCode}
                            userEmail={userEmail}
                            moduleTitle={module.moduleTitle}
                            moduleQuestions={module.auditQuestions}
                            setCommentCallback={setCommentCallback}
                        />
                    ))}
                </Col>

                {changesRequestedItems.length > 0 && <div class="col-md-3 no-print">
                    <div id="inspection-minimap">
                        <h4>Changes Requested</h4>
                        {changesRequestedItems.map((question) => (
                            <div class="change-item" onClick={handleChangeItemClick}>
                                <a href={'#' + question.question}>{question.question}</a>
                            </div>
                        ))}
                    </div>
                </div>}
            </Row>
        </div>
    );
}

export default HomeOwnerAuditForm;
