import React, { useEffect, useState } from "react";
import PagePreview from "../Pages/PagePreview";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../shared/utils/api";
import { useLocation } from "react-router-dom";
import QuizPreview from "../../Quizzes/QuizPreview";
import CourseModuleComplete from "./CourseModuleComplete";
import QuizResult from "../../Quizzes/QuizResult";
import CourseComplete from "../CourseComplete";
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

export default function ModulePreview() {
    const [pageIndex, setPageIndex] = useState(0);
    const [moduleTitle, setModuleTitle] = useState('');
    const [pages, setPages] = useState([]);
    const [courseTitle, setCourseTitle] = useState('');
    const [courseAttemptID, setCourseAttemptID] = useState('');
    const [courseModuleProgressID, setCourseModuleProgressID] = useState(0);

    //Quiz stuff
    const [quizID, setQuizID] = useState(0);
    const [quiz, setQuiz] = useState({});
    const [quizQuestions, setQuizQuestions] = useState([]);
    const [quizQuestionIndex, setQuizQuestionIndex] = useState(0);
    const [quizAttemptID, setQuizAttemptID] = useState(0);
    const [quizScore, setQuizScore] = useState(0);

    const contentStates = {
        loading: "loading",
        courseContent: "courseContent",
        quiz: "quiz",
        moduleComplete: "moduleComplete",
        quizComplete: "quizComplete",
        courseComplete: "courseComplete"
    }

    // State of content on screen
    const [activeState, setActiveState] = useState(contentStates.loading);

    let { state } = useLocation();

    const navigate = useNavigate();

    const params = useParams();
    //get the id passed in from the URL path
    const { id, moduleID } = params;

    const nextModuleCallback = () => {
        callCourseModuleCompleted();
    }

    const moduleCompleteCallback = () => {
        setActiveState(contentStates.moduleComplete);
    }

    const courseCompleteCallback = () => {
        navigate('/courses/' + id);
    }

    const startQuiz = () => {
        if (courseModuleProgressID === '' ||
            courseModuleProgressID === undefined ||
            courseModuleProgressID === null ||
            courseModuleProgressID === 0) {
            return;
        }
        api.post('/quiz-attempts/start', {
            CourseModuleProgressID: parseInt(courseModuleProgressID),
            QuizID: parseInt(quizID)
        }).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setQuizAttemptID(response.quizAttemptID);
                setActiveState(contentStates.quiz);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    const submitQuiz = () => {
        api.post('/quiz-attempts/submit', {
            QuizAttemptID: parseInt(quizAttemptID)
        }).then(
            (response) => {
                setQuizScore(response.score);
                setActiveState(contentStates.quizComplete);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    const nextQuizQuestion = () => {
        if (quizQuestionIndex < quizQuestions.length - 1) {
            setQuizQuestionIndex(quizQuestionIndex + 1);
        }
    };

    const resetQuiz = () => {
        setActiveState(contentStates.quiz);
        setQuizQuestionIndex(0);
        startQuiz();
    }

    const resetCourseModule = () => {
        setPageIndex(0);
        setQuizQuestionIndex(0);
        setActiveState(contentStates.courseContent);
    }

    const callCourseModuleStarted = () => {
        if (courseAttemptID === '' || courseAttemptID === undefined || courseAttemptID === null) {
            return;
        }
        if (moduleID === '' || moduleID === undefined || moduleID === null) {
            return;
        }

        api.post('/course-module-progress/start', {
            courseAttemptID: parseInt(courseAttemptID),
            courseModuleID: parseInt(moduleID)
        }).then(
            // TODO: What do we do with the response if it fails
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setCourseModuleProgressID(response.courseModuleProgressID);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });

    }

    const callCourseModuleCompleted = () => {
        if (courseAttemptID === '' || courseAttemptID === undefined || courseAttemptID === null) {
            return;
        }
        if (moduleID === '' || moduleID === undefined || moduleID === null) {
            return;
        }

        api.post('/course-module-progress/complete', {
            courseModuleProgressID: parseInt(courseModuleProgressID),
            courseAttemptID: parseInt(courseAttemptID)
        }).then(
            // TODO: What do we do with the response?
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                if (response.isCourseComplete) {
                    setActiveState(contentStates.courseComplete);
                }
                if (!response.isCourseComplete) {
                    navigate('/courses/' + id);
                }
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    const fetchCourseAttempt = () => {
        api.get('/course-attempts?courseID=' + id).then(
            (response) => {
                setCourseAttemptID(response.courseAttemptID);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    useEffect(() => {
        //look up the course module by id
        if (state) {
            setCourseTitle(state.courseTitle);
            setCourseAttemptID(state.courseAttemptID);
        }
        else {
            // Need to look up course attempt ID and course title from DB. For the moment lets log
            console.log("Course attempt ID and course title not found in state");
            fetchCourseAttempt();
        }
        const fetchCourseModule = () => {
            api.get('/modules/' + moduleID).then(
                (response) => {
                    if (response === null) return;
                    if (response === undefined) return;
                    setModuleTitle(response.moduleTitle);
                    if (response.quizID !== null && response.quizID !== undefined) {
                        setQuizID(response.quizID);
                    }
                },
                (error) => {
                    toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
                });
        }

        const fetchCoursePages = () => {
            api.get('/pages?moduleID=' + moduleID).then(
                (response) => {
                    setActiveState(contentStates.courseContent);
                    if (response === null) return;
                    if (response === undefined) return;
                    //sort the pages by pageOrder
                    response.sort((a, b) => (a.pageOrder > b.pageOrder) ? 1 : -1)
                    console.log(response);
                    console.log(response);
                    console.log(response);
                    setPages(response);
                },
                (error) => {
                    toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
                });
        }
        fetchCourseModule();
        fetchCoursePages();
    }, [moduleID]);

    useEffect(() => {
        callCourseModuleStarted();
    }, [courseAttemptID, moduleID]);

    useEffect(() => {
        const fetchQuizQuestions = () => {
            if (quizID === 0) {
                setQuizQuestions([]);
                return;
            }

            api.get('/quiz-questions?quizID=' + quizID).then(
                (response) => {
                    if (response === null) return;
                    if (response === undefined) return;
                    //sort the questions by questionOrder
                    response.sort((a, b) => (a.questionOrder > b.questionOrder) ? 1 : -1)
                    setQuizQuestions(response);
                },
                (error) => {
                    toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
                });
        }

        const fetchQuiz = () => {
            if (quizID === 0) {
                return;
            }

            api.get('/quizzes/' + quizID).then(
                (response) => {
                    if (response === null) return;
                    if (response === undefined) return;
                    setQuiz(response);
                },
                (error) => {
                    toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
                });
        }

        fetchQuizQuestions();
        fetchQuiz();
    }, [quizID]);

    function NextButton() {
        if (pageIndex === pages.length - 1) {
            if (quizID === 0) {
                return <button class="btn-standard float-right" onClick={moduleCompleteCallback}>Complete Module</button>
            }
            return <button class="btn-standard float-right" onClick={startQuiz}>
                Start Quiz</button>;
        }

        return (
            <button class="btn-standard float-right" onClick={() => {
                if (pageIndex < pages.length - 1) {
                    setPageIndex(pageIndex + 1);
                }
            }
            }>Next</button>);
    }

    function DisplayPages() {
        if (activeState === contentStates.loading) {
            return <p>Loading...</p>
        }

        if (pages.length === 0) {
            return <p>No pages to display</p>
        }

        return (
            <div>
                <PagePreview pageTitle={pages[pageIndex].pageTitle}
                    pageContent={pages[pageIndex].pageContent}
                    presignedURL={pages[pageIndex].presignedURL} />
                <br />
                <button class="btn-standard" onClick={() => {
                    if (pageIndex > 0) {
                        setPageIndex(pageIndex - 1);
                    }
                }
                }>Previous</button>
                <NextButton />
            </div>
        );
    }

    function DisplayContent() {
        if (activeState === contentStates.quizComplete) {
            return <QuizResult
                score={quizScore}
                moduleCompleteCallback={moduleCompleteCallback}
                retryQuizCallback={resetQuiz}
                restartCourseModuleCallback={resetCourseModule} />
        }

        if (activeState === contentStates.quiz) {
            return <QuizPreview
                quiz={quiz}
                quizAttemptID={quizAttemptID}
                quizQuestions={quizQuestions}
                questionIndex={quizQuestionIndex}
                nextQuestionCallback={nextQuizQuestion}
                submitQuizCallback={submitQuiz} />
        }

        if (activeState === contentStates.moduleComplete) {
            return <CourseModuleComplete nextModuleCallback={nextModuleCallback} />
        }

        if (activeState === contentStates.courseComplete) {
            return <CourseComplete courseCompleteCallback={courseCompleteCallback} />
        }

        return <DisplayPages moduleCompleteCallback={moduleCompleteCallback} />
    }

    return (
        <div>
            <h3 class="float-right">{moduleTitle}</h3>
            <h2>{courseTitle}</h2>
            <DisplayContent />
        </div>
    );
}
