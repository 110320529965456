import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../shared/utils/api';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../shared/Constants';

export default function AddQuiz() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const navigate = useNavigate();

    const addQuiz = (title, description) => {

        api.post('/quizzes', { quizID: 0, quizTitle: title, quizDescription: description }).then(
            (response) => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        addQuiz(title, description);

        setTitle('');
        setDescription('');
    }

    return (
        <form onSubmit={handleSubmit}>
            <h2>Add new Quiz</h2>
            <div className="input-container">
                <label htmlFor="quizTitle">Quiz Title</label>
                <br />
                <input
                    name="quizTitle"
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </div>
            <div className="input-container">
                <label htmlFor="quizDescription">Quiz Description</label>
                <br />
                <textarea
                    name="quizDescription"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}>
                </textarea>
            </div>
            <br />
            <br />
            <button type="submit" className="btn-submit">Save</button>
        </form>
    )
};
