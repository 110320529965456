import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { HiOutlineUserCircle } from "react-icons/hi2";
// UserProfileHeader component is a component that displays header information that is specific
// to the user's profile. This includes a login/logout button that is conditionally displayed
// based on whether or not the user is logged in.
function UserProfileHeader({ isLoggedIn, logoutCallBack, user }) {
  return (
    <div className="userProfileHeader navbar-nav">
  
      <LoginLogout
        isLoggedIn={isLoggedIn}
        onLogout={logoutCallBack}
        user={user} />
    </div>
  );
};

function LoginLogout({
  isLoggedIn,
  onLogout,
  user
}) {

  return (
    <div>
      {isLoggedIn ? (
        <div class="nav-item dropdown">
          <a class="nav-link dropdown-toggle text-black rounded-md px-3 py-2" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <HiOutlineUserCircle className="h-5 w-5 text-muted-foreground inline-block -mt-0.5 mr-2" />
            {user.firstname} {user.lastname}
          </a>
          <ul class="dropdown-menu">
            <li><Link to="user-profile" className={"dropdown-item text-black"}>Profile</Link></li>
            <li><a class="dropdown-item text-black" href="#" onClick={onLogout}>Logout</a></li>
          </ul>
        </div>
      ) : (
        <NavLink to="/login" className="nav-link text-black rounded-md px-3 py-2">Login</NavLink>
      )}
    </div>
  );
}

export default UserProfileHeader;
