import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

export const isTokenExpired = () => {
  const expiration = localStorage.getItem("tokenExpiration");
  if (!expiration) {
    return Cookies.get("authToken");
  }
  return new Date() > new Date(expiration);
};
export const getStoredAuthToken = () => Cookies.get("authToken");

export const storeAuthToken = (token) => {
  // 1/144 represents roughly 10 minutes, 1/24 represents one hour
  Cookies.set("authToken", token, {
    expires: 1 / 24,
    sameSite: "none",
    secure: true,
  });

  const expirationTime = new Date(new Date().getTime() + 40 * 60 * 1000); // 40 minutes from now
  localStorage.clear();
  localStorage.setItem("tokenExpiration", expirationTime.toISOString());
};

export const removeStoredAuthToken = () => {
  Cookies.remove("authToken");
  localStorage.clear();
};

export const getLoggedInUser = () => {
  const token = getStoredAuthToken();
  if (token) {
    const decoded = jwtDecode(token);
    return decoded.principal.firesmartUser;
  }
  return null;
};

export const getUserRole = () => {
  const user = getLoggedInUser();
  return user?.userRoleID;
};

export const isUserAdmin = () => {
  const userRole = getUserRole();
  return userRole === 1 || userRole === 2;
};

export const canUserViewAssessments = () => {
  const user = getLoggedInUser();
  return user && user.canAssess;
};
