import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../shared/utils/api';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../shared/Constants';

export default function AddCourse(props) {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const navigate = useNavigate();

    const addCourse = (title, description) => {
        api.post('/courses', { courseID: 0, courseTitle: title, courseDescription: description }).then(
            (response) => {
                navigate("/Courses");
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        addCourse(title, description);

        setTitle('');
        setDescription('');
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Add new Course</h2>
            <div className="input-container">
                <label htmlFor="courseTitle">Course Title</label>
                <br />
                <input
                    name="courseTitle"
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </div>
            <div className="input-container">
                <label htmlFor="courseDescription">Course Description</label>
                <br />
                <textarea
                    name="courseDescription"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}>
                </textarea>
            </div>
            <br />
            <br />
            <button type="submit" className="btn-submit">Save</button>
        </form>
    )
};
