import React from 'react';
import { useState, useEffect } from 'react';
import EnrollmentRequest from './EnrollmentRequest';
import ApprovalRequest from './ApprovalRequest';
import api from '../shared/utils/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../shared/Constants';

function CertificationEntitlement() {
    const [certEntitlement, setCertEntitlement] = useState({});
    const [showEnrollmentRequest, setShowEnrollmentRequest] = useState(false);
    const [showApprovalRequest, setShowApprovalRequest] = useState(false);

    const params = useParams();
    const { id } = params;

    const fetchEnrollmentRequest = () => {
        api.get('/certification-entitlements/' + id).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setCertEntitlement(response);

                setShowEnrollmentRequest(false);
                setShowApprovalRequest(false);

                if (response.certificationStatus === "Requested") {
                    setShowEnrollmentRequest(true);
                }

                if (response.certificationStatus === "Ready for Approval") {
                    setShowApprovalRequest(true);
                }
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    useEffect(() => {
        fetchEnrollmentRequest();
    }, []);

    function PendingAction() {
        if (showEnrollmentRequest) {
            return <EnrollmentRequest
                certEntitlement={certEntitlement}
                refreshCallback={fetchEnrollmentRequest} />
        }

        if (showApprovalRequest) {
            return <ApprovalRequest
                certEntitlement={certEntitlement}
                refreshCallback={fetchEnrollmentRequest} />
        }

        return null;
    }

    return (
        <form class="form-container">
            <h4>Certification Status</h4>
            <div class="row gy-2 gx-1">
                <div class="form-group">
                    <label class="form-label">Certification</label>
                    <input
                        class="form-control"
                        name="certificationName"
                        type="text"
                        value={certEntitlement.certificationName}
                        placeholder='Certification Name'
                        disabled
                    />
                </div>
                <div class="form-group">
                    <label class="form-label">Status</label>
                    <input
                        class="form-control"
                        type="text"
                        value={certEntitlement.certificationStatus}
                        placeholder='Certification Name'
                        disabled
                    />
                </div>
                <div class="form-group">
                    <label class="form-label" htmlFor="firstName">Name</label>
                    <div className="row g-2">
                        <div class="col">
                            <input
                                class="form-control"
                                name="firstName"
                                type="text"
                                value={certEntitlement.userFirstName}
                                placeholder='First Name'
                                disabled
                            />
                        </div>
                        <div class="col">
                            <input
                                class="form-control"
                                name="lastName"
                                type="text"
                                value={certEntitlement.userLastName}
                                placeholder='Last Name'
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <PendingAction />
                </div>
            </div>
        </form>
    );
}

export default CertificationEntitlement;
