import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

export default function Root({ isLoggedIn, logoutCallBack }) {
    return (
        <>
            <Header isLoggedIn={isLoggedIn} logoutCallBack={logoutCallBack} />
            <main class="fs-container">
                <Outlet />
            </main>
            <Footer />
        </>
    );
}
