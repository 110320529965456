import React from "react";
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from "../../../shared/utils/api";
import FormControlInput from "../../../shared/components/FormControlInput";
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

export default function AddAuditQuestionOption() {
    const [header, setHeader] = useState('');
    const [option, setOption] = useState('');
    const [sortOrder, setSortOrder] = useState(0);

    const navigate = useNavigate();

    const params = useParams();

    const { questionID } = params;

    const handleSubmit = (e) => {
        e.preventDefault();
        api.post('/audit-question-options', {
            auditQuestionID: parseInt(questionID),
            header: header,
            option: option,
            sortOrder: parseInt(sortOrder)
        }).then(
            () => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    return (
        <div>
            <form class="form-container" onSubmit={handleSubmit}>
                <h4>Add Question Option</h4>
                <FormControlInput label="Header" type="text" value={header} onChange={(e) => setHeader(e.target.value)} />
                <FormControlInput label="Option" type="text" value={option} onChange={(e) => setOption(e.target.value)} />
                <FormControlInput label="Sort Order" type="number" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} />
                <br />
                <button class="btn-standard" type="submit">Save</button>
            </form>
        </div>
    );
};
