import { ERROR_MESSAGE_DURATION } from "../Constants";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import api from "../utils/api";
import { toast } from "react-toastify";

function FormGroupAuditDropDownInput({
  value,
  onChange,
  required,
  placeHolderRequired,
}) {
  const [auditList, setAuditList] = useState([]);

  const fetchAuditList = () => {
    api.get("/audits").then(
      (response) => {
        if (response === null) return;
        if (response === undefined) return;
        setAuditList(response);
      },
      (error) => {
        toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
      },
    );
  };

  useEffect(() => {
    fetchAuditList();
  }, []);

  return (
    <Form.Group as={Col} controlId="audit" class="form-group">
      <Form.Label className="font-bold">Assessment Type</Form.Label>
      <Form.Select
        required={required}
        class="form-select"
        onChange={onChange}
        value={value}
        disabled={auditList.length <= 1}
      >
        {auditList.map((audit) => (
          <option key={audit.auditID} value={audit.auditID}>
            {audit.auditTitle}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        Must select one of the assessment types listed.
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export default FormGroupAuditDropDownInput;
