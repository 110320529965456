import React from 'react';
import HomeOwnerQuestion from './HomeOwnerQuestion';

function HomeOwnerModule({
    auditID,
    homeInspectionID,
    accessCode,
    userEmail,
    moduleTitle,
    moduleQuestions,
    setCommentCallback,
}) {
    return (
        <div>
            <h3 class="font-weight-bold">{moduleTitle}</h3>
            {moduleQuestions && moduleQuestions.map((question, index) => (
                <HomeOwnerQuestion
                    auditID={auditID}
                    homeInspectionID={homeInspectionID}
                    accessCode={accessCode}
                    userEmail={userEmail}
                    questionID={question.auditQuestionID}
                    questionContent={question.question}
                    questionAnswers={question.auditQuestionOptions}
                    inspectorComment={question.comment}
                    homeOwnerComment={question.homeOwnerComment}
                    requiresActionValue={question.requiresAction}
                    images={question.images}
                    setCommentCallback={setCommentCallback}
                />
            ))}
        </div>
    );
}

export default HomeOwnerModule;
