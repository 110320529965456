import React from "react";

function MediaPreview({ mediaLink }) {
    if (mediaLink === undefined || mediaLink === "" || mediaLink === null) {
        return null;
    }
    else {
        return (<video poster={mediaLink} src={mediaLink} alt="Nothing to display" autoplay />);
    }
}

export default function PagePreview(props) {
    const mediaIsSet = (props.presignedURL === undefined || 
        props.presignedURL === "" ||
        props.presignedURL === null) ? "page-content" : "page-content with-media";

    return (
        <div class="page-preview">
            <h2 class="no-margin-top">{props.pageTitle}</h2>
            <div className={mediaIsSet}>
                <MediaPreview mediaLink={props.presignedURL} />
                <p>{props.pageContent}</p>
            </div>
        </div>
    );
}
