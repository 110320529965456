import React from 'react';

function CheckBox({ name, label, checked, onChange, bold, className }) {
    return (
        <label className={`mb-1.5 flex items-center space-x-2 ${className}`}>
            <input
                type="checkbox"
                name={name}
                checked={checked}
                onChange={onChange}
                className="form-checkbox h-5 w-5 text-slate bg-gre"
            />
            
            <span className={bold ? "font-bold" : ""}>{label}</span>
        </label>
    )
}

export default CheckBox;
