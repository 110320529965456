import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import UserProfileHeader from "./UserProfileHeader";
import NavLinkAuth from "./NavLinkAuth";
import { getLoggedInUser } from "../shared/utils/authToken";
import { GoHome } from "react-icons/go";
import { LiaClipboardCheckSolid } from "react-icons/lia";
import { IoPeopleOutline } from "react-icons/io5";
import { IoMdBook } from "react-icons/io";
import { LiaClipboardListSolid } from "react-icons/lia";
import { IoEyeOutline } from "react-icons/io5";
import { HiMenu, HiX } from "react-icons/hi";

export default function Header({ isLoggedIn, logoutCallBack }) {
  const [user, setUser] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const userIsAdmin = () => {
    return (user && user.userRoleID === 1) || user.userRoleID === 2;
  };

  const canViewAssessments = () => {
    return (
      user && (user.canAssess || user.userRoleID === 1 || user.userRoleID === 2)
    );
  };

  useEffect(() => {
    if (isLoggedIn) {
      const user = getLoggedInUser();
      setUser(user);
    }

    if (!isLoggedIn) {
      setUser({});
    }
  }, [isLoggedIn]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close the menu when a link is clicked
  const handleLinkClick = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <div className="header no-print border-b border-border bg-offWhite">
      <div className="fs-container">
        <nav className="flex items-center justify-between flex-wrap py-3">
          {/* Logo */}
          <div className="flex items-center flex-shrink-0 mr-3">
            <NavLink to="/" exact>
              <img src="/logo-black.svg" alt="FireSmart" className="w-[150px] xl:w-[200px]" />
            </NavLink>
          </div>
          {/* Hamburger Menu Button */}
          <div className="block lg:hidden">
            <button
              className="text-gray-700 focus:outline-none"
              onClick={toggleMenu}
              aria-label="Toggle menu"
            >
              {isMenuOpen ? (
                <HiX className="h-6 w-6" />
              ) : (
                <HiMenu className="h-6 w-6" />
              )}
            </button>
          </div>
          {/* Navigation Links */}
          <div
            className={`w-full lg:w-auto mt-3 lg:!mt-0  ${
              isMenuOpen ? "block" : "hidden"
            } lg:block lg:flex lg:items-center`}
          >
            <ul className="o lg:flex md:space-x-1 xl:space-x-3 mb-0 pl-0 mt-0">
              <li>
                <NavLinkAuth
                  to="/"
                  authorized={isLoggedIn}                
                  onClick={handleLinkClick} // Ensures menu closes on click
                >
                  <GoHome className="mr-2 h-5 w-5 -mt-0.5 inline-block" />
                  Home
                </NavLinkAuth>
              </li>
              {canViewAssessments() && (
                <li>
                  <NavLinkAuth
                    to="/home-inspections"
                    authorized={isLoggedIn}                
                    onClick={handleLinkClick} // Ensures menu closes on click
                  >
                    <LiaClipboardCheckSolid className="mr-2 h-5 w-5 -mt-0.5 inline-block" />
                    Assessments
                  </NavLinkAuth>
                </li>
              )}
              {userIsAdmin() && (
                <li>
                  <NavLinkAuth
                    to="/Users"
                    authorized={isLoggedIn}                   
                    onClick={handleLinkClick} // Ensures menu closes on click
                  >
                    <IoPeopleOutline className="mr-2 h-5 w-5 inline-block -mt-0.5" />
                    Users
                  </NavLinkAuth>
                </li>
              )}
              {/* <li>
                <NavLinkAuth
                  to="/Courses"
                  authorized={isLoggedIn}                
                  onClick={handleLinkClick} // Ensures menu closes on click
                >
                  <IoMdBook className="mr-2 h-5 w-5 inline-block" />
                  Training
                </NavLinkAuth>
              </li> */}
              {!isLoggedIn && (
                <>
                  <li>
                    <NavLink
                      to="/request-home-inspection"
                      className="flex items-center px-3 py-2 text-black hover:bg-gray-200 rounded-md"
                      onClick={handleLinkClick} // Ensures menu closes on click
                    >
                      <LiaClipboardListSolid className="mr-2 h-5 w-5 -mt-1 inline-block" />
                      Request Assessment
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/view-home-inspection"
                      className="flex items-center px-3 py-2 text-black hover:bg-gray-200 rounded-md"
                      onClick={handleLinkClick} // Ensures menu closes on click
                    >
                      <IoEyeOutline className="mr-2 h-5 w-5 -mt-1 inline-block" />
                      View Assessment
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
            <div className="block lg:hidden">
              <UserProfileHeader
                isLoggedIn={isLoggedIn}
                logoutCallBack={logoutCallBack}
                user={user}
                onClick={handleLinkClick} // Optional: Ensure profile actions also close the menu
              />
            </div>
          </div>
          {/* User Profile */}
          <div className="hidden lg:block">
            <UserProfileHeader
              isLoggedIn={isLoggedIn}
              logoutCallBack={logoutCallBack}
              user={user}
            />
          </div>
        </nav>
      </div>
    </div>
  );
}