import { useNavigate } from "react-router-dom"

export default function NotFound() {

    const navigate = useNavigate();

    const handleGoHome = (e) => {
        e.preventDefault();
        navigate('/');
    }

    const handleGoBack = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    return (
        <div>
            <h1>Error 404!</h1>
            <p>We could not find the page you were looking for.</p>
            <button className="btn-standard margin-right-10"
            type="submit"
            onClick={handleGoHome}>Homepage</button>
            <button className="btn-standard margin-right-10"
            type="submit"
            onClick={handleGoBack}>Go Back</button>
        </div>
    )
}
