import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import api from '../../shared/utils/api';
import CourseRegister from './CourseRegister';
import RegistrationSubmitted from './RegistrationSubmitted';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../shared/Constants';

function CourseView() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [imageLink, setImageLink] = useState('');
    const [certificationID, setCertificationID] = useState('');
    const [modules, setModules] = useState([]);
    const [accessStatus, setAccessStatus] = useState('');
    const [showRegister, setShowRegister] = useState(false);
    const [showRegisterSubmitted, setShowRegisterSubmitted] = useState(false);
    const [enrollomentReason, setEnrollmentReason] = useState('');
    const [courseAttemptID, setCourseAttemptID] = useState('');
    const [courseModuleProgresses, setCourseModuleProgresses] = useState([]);
    const [badgeColour, setBadgeColour] = useState(null);

    let location = useLocation();
    var state = location.state;

    const navigate = useNavigate();

    const params = useParams();
    //get the id passed in from the URL path
    const { id } = params;

    //look up the course by id
    const fetchCourse = () => {
        if (state) {
            // if the course is passed in from the previous page, use that while we fetch the data
            // from the server
            setTitle(state.courseTitle);
            setDescription(state.courseDescription);
            setImageLink(state.presignedURL);
        }
        api.get('/courses/' + id).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setTitle(response.courseTitle);
                setDescription(response.courseDescription);
                setImageLink(response.presignedURL);
                setCertificationID(response.certificationID);

                if (response.certificationBadgeColour) {
                    setBadgeColour(response.certificationBadgeColour);
                }

                fetchCourseAttempt();
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    const fetchCourseModules = () => {
        api.get('/modules?courseID=' + id).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                //sort the modules by moduleOrder
                response.sort((a, b) => (a.moduleOrder > b.moduleOrder) ? 1 : -1)
                setModules(response);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    const fetchCourseAttempt = () => {
        api.get('/course-attempts?courseID=' + id).then(
            (response) => {
                setCourseAttemptID(response.courseAttemptID);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    const navigateToModule = (moduleID) => {
        if (accessStatus === "Approved") {
            navigate("/courses/" + id + "/modules/" + moduleID + "/preview",
                { state: { courseTitle: title, courseAttemptID: courseAttemptID } });
        }
    }

    const submitEnrollmentRequest = (e) => {
        e.preventDefault();
        api.post('/certification-entitlements/request', {
            certificationID: certificationID,
            requestNote: enrollomentReason
        }).then(
            () => {
                setShowRegisterSubmitted(true);
                checkCourseAccessStatus();
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    const checkCourseAccessStatus = () => {
        if (certificationID === "" || certificationID === null || certificationID === undefined) {
            setAccessStatus("Approved");
            return;
        }
        api.get('/certification-entitlements/check-course-access?certificationID=' + certificationID).then(
            (response) => {
                if (response.status === "NoAccess") {
                    setAccessStatus("NoAccess");
                }
                if (response.status === "Approved") {
                    setAccessStatus("Approved");
                }
                if (response.status === "Requested") {
                    setAccessStatus("Requested");
                }
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
                setAccessStatus("");
            });
    }

    const getCourseModuleProgress = (courseAttemptID) => {
        if (courseAttemptID === "" || courseAttemptID === null || courseAttemptID === undefined) {
            return;
        }
        api.get('/course-module-progress?courseAttemptID=' + courseAttemptID).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;

                setCourseModuleProgresses(response);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    const checkModuleCompleted = (moduleID) => {
        if (courseModuleProgresses === null || courseModuleProgresses === undefined) {
            return "";
        }

        var returnMsg = "";

        courseModuleProgresses.forEach((moduleProgress) => {
            if (moduleProgress.courseModuleID === moduleID) {
                if (moduleProgress.isComplete) {
                    returnMsg = <FontAwesomeIcon icon={faCheck} />;
                }
            }
        });

        return returnMsg;
    }

    const modulesCompleted = () => {
        if (courseModuleProgresses === null || courseModuleProgresses === undefined) {
            return 0;
        }

        return courseModuleProgresses.filter(
            (moduleProgress) => moduleProgress.isComplete).length;
    }


    const enterEnrollCourseButton = () => {
        if (accessStatus === "NoAccess") {
            return <button class="btn-standard" onClick={requestEnrollment}>Enroll</button>;
        }
        if (accessStatus === "Requested") {
            return <button class="btn disabled" disabled>Pending</button>;
        }

        return null;
    }

    const onEnrollmentReasonChange = (e) => {
        setEnrollmentReason(e.target.value);
    }

    const requestEnrollment = () => {
        setShowRegister(true);
    }

    const hideEnrollment = () => {
        setShowRegister(false);
        setShowRegisterSubmitted(false);
        setEnrollmentReason('');
    }

    const mainContent = () => {
        if (showRegisterSubmitted) {
            return <RegistrationSubmitted backCallback={hideEnrollment} />
        }
        return (
            <div class="row">
                {moduleContent()}
                <div class="col-4">
                    <div class="">
                        <div class="course-listing">
                            <img width="400px" height="254px" src={imageLink} alt={title} />
                            <div class="course-listing-content">
                                <h3 class="no-margin-top">About this course</h3>
                                <p>{description}</p>
                                <p>{modules.length} modules in this course. {modulesCompleted()}/{modules.length} completed.</p>
                                {enterEnrollCourseButton()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const moduleContent = () => {
        if (showRegister) {
            return <CourseRegister courseName={title}
                cancelCallback={hideEnrollment}
                submitCallback={submitEnrollmentRequest}
                enrollmentReasonChangeCallback={onEnrollmentReasonChange} />
        }

        return (
            <div class="col">
                <h4 class="no-margin-top">Course Module Overview</h4>
                <p>{description}</p>
                <div>
                    <ul class="module-listing">
                        {modules.map((module, index) => (
                            <li key={module.courseModuleID} onClick={() => navigateToModule(module.courseModuleID)}>
                                <span class={accessStatus !== "Approved" ? "no-hover" : ""}>Module {index + 1} - {module.moduleTitle}</span>
                                <span class="float-right text-colour-green">{checkModuleCompleted(module.courseModuleID)}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }

    useEffect(() => {
        fetchCourse()
        fetchCourseModules()
    }, []);

    useEffect(() => {
        checkCourseAccessStatus()
    }, [certificationID]);

    useEffect(() => {
        getCourseModuleProgress(courseAttemptID)
    }, [courseAttemptID]);

    return (
        <div>
            <h1>
                {title}&nbsp;
                {badgeColour && <i class="fa-solid fa-scroll" style={{ 'color': badgeColour }}></i>}
            </h1>
            {mainContent()}
        </div>
    );
}

export default CourseView;
