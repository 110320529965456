import React from "react";
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from "../../../shared/utils/api";
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

export default function QuizAnswer() {
    const [content, setContent] = useState('');
    const [answerOrder, setAnswerOrder] = useState(0);
    const [isCorrect, setIsCorrect] = useState(false);

    const navigate = useNavigate();

    const params = useParams();

    const { answerID } = params;

    useEffect(() => {
        const fetchQuizAnswer = () => {
            api.get('/quiz-answers/' + answerID).then(
                (response) => {
                    if (response === null) return;
                    if (response === undefined) return;
                    setContent(response.answerContent);
                    setAnswerOrder(response.answerOrder);
                    setIsCorrect(response.isCorrect);
                },
                (error) => {
                    toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
                }
            );
        }

        fetchQuizAnswer();
    }, [answerID]);

    const handleSubmit = (e) => {
        e.preventDefault();

        api.post('/quiz-answers', {
            quizAnswerID: parseInt(answerID),
            answerContent: content,
            answerOrder: parseInt(answerOrder),
            isCorrect: isCorrect
        }).then(
            (response) => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    const handleDelete = (e) => {
        e.preventDefault();

        api.delete('/quiz-answers/' + answerID).then(
            (response) => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    const checkHandler = (e) => {
        setIsCorrect(!isCorrect)
    }

    return (
        <div>
            <h2>Quiz Answer</h2>
            <form onSubmit={handleSubmit}>
                <label htmlFor="answerContent">Answer Content</label>
                <br />
                <textarea
                    value={content}
                    name="answerContent"
                    onChange={(e) => setContent(e.target.value)} />
                <br />
                <label htmlFor="answerOrder">Answer Order</label>
                <br />
                <input
                    type="number"
                    value={answerOrder}
                    name="answerOrder"
                    onChange={(e) => setAnswerOrder(e.target.value)} />
                <br />
                <label htmlFor="isCorrect">Correct Answer?</label>
                <br />
                <input
                    type="checkbox"
                    name="isCorrect"
                    checked={isCorrect}
                    onChange={checkHandler} />
                <br />
                <button type="submit">Save</button>
                <button onClick={handleDelete}>Delete</button>
            </form>
        </div>
    );
};
