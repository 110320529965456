import React from "react";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../shared/utils/api';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../shared/Constants';

function Quizzes() {
    const [quizzes, setQuizzes] = useState([]);

    const fetchQuizzes = () => {
        api.get('/quizzes').then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setQuizzes(response);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    useEffect(() => {
        fetchQuizzes()
    }, []);

    const navigate = useNavigate();

    const handleRowClick = (id) => {
        navigate(id.toString());
    }

    const handleAdd = () => {
        navigate('add');
    }

    return (
        <div>
            <h2>Quizzes</h2>
            <table>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {quizzes.map((quiz) =>
                        <tr key={quiz.quizID} onClick={() => handleRowClick(quiz.quizID)}>
                            <td>{quiz.quizTitle}</td>
                            <td>{quiz.quizDescription}</td>
                        </tr>
                    )}
                </tbody>
            </table>
            
            <br />
            <button onClick={handleAdd}>Add</button>
        </div>
    );
}

export default Quizzes;
