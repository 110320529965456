import React from "react";

// TODO: Is this file needed anymore since there isn't any content being displayed?
export default function Footer() {

    return (
        <footer>

        </footer>
    );
}
