import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../shared/utils/api';
import FormControlInput from '../../../shared/components/FormControlInput';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

export default function AddAuditQuestion() {
    const [question, setQuestion] = useState('');
    const [questionDescription, setQuestionDescription] = useState('');
    const [sortOrder, setSortOrder] = useState(0);
    const [defaultComment, setDefaultComment] = useState('');

    const navigate = useNavigate();

    const params = useParams();

    const { moduleID } = params;

    const handleSubmit = (e) => {
        e.preventDefault();

        api.post('/audit-questions', {
            auditModuleID: parseInt(moduleID),
            question: question,
            questionDescription: questionDescription,
            sortOrder: parseInt(sortOrder),
            defaultComment: defaultComment
        }).then(
            (response) => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    };

    return (
        <div>
            <form class="form-container" onSubmit={handleSubmit}>
                <h4>Add Assessment Question</h4>
                <FormControlInput label="Question" type="text" value={question} onChange={(e) => setQuestion(e.target.value)} />
                <FormControlInput label="Question Description" type="text" value={questionDescription} onChange={(e) => setQuestionDescription(e.target.value)} />
                <FormControlInput label="Sort Order" type="number" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} />
                <FormControlInput label="Default Comment" type="text" value={defaultComment} onChange={(e) => setDefaultComment(e.target.value)} />
                <br />
                <button type="submit" className="btn-standard">Save</button>
            </form>
        </div>
    );
};
