import React from 'react';

function CourseStatus(props) {
    const getBadge = (status) => {
        switch (status) {
            case 'Completed': return 'bg-green-500';
            case 'In Progress': return 'bg-gray-200';
            default: return 'bg-gray-200';
        }
    }

    return (
        <div class="position-absolute top-3 left-3 z-10">
            <span class={`${getBadge(props.status)} rounded-full px-2.5 pt-1 pb-0.5  text-xs font-bold inline-block`}>{props.status}</span>
        </div>
    );
}

export default CourseStatus;
