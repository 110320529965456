import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../../shared/utils/api';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../shared/Constants';

function Quiz() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [quizQuestions, setQuizQuestions] = useState([]);

    const navigate = useNavigate();

    const params = useParams();

    const { id } = params;

    useEffect(() => {
        const fetchQuiz = () => {
            api.get('/quizzes/' + id).then(
                (response) => {
                    if (response === null) return;
                    if (response === undefined) return;
                    setTitle(response.quizTitle);
                    setDescription(response.quizDescription);
                },
                (error) => {
                    toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
                });
        }
    
        const fetchQuizQuestions = () => {
            api.get('/quiz-questions?quizID=' + id).then(
                (response) => {
                    if (response === null) return;
                    if (response === undefined) return;
                    //sort the questions by questionOrder
                    response.sort((a, b) => (a.questionOrder > b.questionOrder) ? 1 : -1)
                    setQuizQuestions(response);
                },
                (error) => {
                    toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
                });
        }

        fetchQuiz();
        fetchQuizQuestions();
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();

        api.post('/quizzes', {
            quizID: parseInt(id),
            quizTitle: title,
            quizDescription: description
        }).then(
            (response) => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    const handleDelete = (e) => {
        e.preventDefault();
        
        api.delete('/quiz-questions?quizID=' + id).then(
            (response) => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    return (
        <div>
            <h2>Quiz</h2>
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label htmlFor="quizTitle">Title</label>

                    <br />
                    <input
                        name="quizTitle"
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="quizDescription">Description</label>
                    <br />
                    <textarea
                        name="quizDescription"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}>
                    </textarea>
                </div>
                <br />
                <button type="submit" className="btn-submit">Save</button>
                <button onClick={handleDelete} className="btn-delete">Delete</button>
            </form>
            <h3>Questions</h3>
            <ul>
                {quizQuestions.map((question) => (
                    <li key={question.questionID}>
                        <Link to={`/quizzes/${id}/questions/${question.quizQuestionID}`}>{question.questionTitle}</Link>
                    </li>
                ))}
            </ul>

            <button onClick={() => navigate(`/quizzes/${id}/questions/add`)} className="btn-new-question">New Question</button>
            <br />
            <button onClick={() => navigate(`/quizzes/${id}/questions/preview`, { state: { quizQuestions } })} className="btn-preview">Preview</button>
            <br />
            <br />
        </div>
    );
}

export default Quiz;
