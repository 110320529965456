import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import api from '../../shared/utils/api';
import FormControlInput from '../../shared/components/FormControlInput';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import FormGroupInput from '../../shared/components/FormGroupInput';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { ERROR_MESSAGE_DURATION } from '../../shared/Constants';

export default function VerifyHomeInspection() {
    const [validated, setValidated] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [homeOwnerEmail, setHomeOwnerEmail] = useState('');
    const [homeInspectionAccessCode, setHomeInspectionAccessCode] = useState('');
    const [homeInspectionStatus, setHomeInspectionStatus] = useState('');
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // TODO: useEffect is called twice which makes the verification toast come up twice
        var email = searchParams.get('email');
        var accessCode = searchParams.get('accessCode');

        if (email && accessCode) {
            emailVerifyHomeInspection(email, accessCode);
        }
    }, []);

    const emailVerifyHomeInspection = (email, accessCode) => {
        setHomeOwnerEmail(email);
        setHomeInspectionAccessCode(accessCode);

        if (email && accessCode) {
            api.get('/verify-home-inspection?email=' + encodeURIComponent(email) + '&accessCode=' + accessCode).then(
                (response) => {
                    setHomeInspectionStatus(response.homeInspectionStatus);
                    setActiveIndex(1);
                    toast.success("Your home assessment request has been verified!", {
                        autoClose: 2000
                    });
                },
                (error) => {
                    // this check is because useEffect is called twice so the verification happens twice from email link
                    if (error.message === "HomeInspection is not in a Requested state, no need to verify" || error.message === "Failed to update home inspection during verification: No update done on home inspection as no changes detected.") {
                        setActiveIndex(1);
                    } else {
                        toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
                    }
                }
            );
        }
    }

    const formVerifyHomeInspection = (homeOwnerEmail, homeInspectionAccessCode) => {
        api.get('/verify-home-inspection?email=' + encodeURIComponent(homeOwnerEmail) + '&accessCode=' + homeInspectionAccessCode).then(
            (response) => {
                setHomeInspectionStatus(response.homeInspectionStatus);
                setActiveIndex(1);
                toast.success("Your home assessment request has been verified!", {
                    autoClose: 2000
                });

            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    const handleViewHomeInspection = (e) => {
        e.preventDefault();
        window.location.href = '/view-home-inspection';
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
            return; // Return if validation failed
        }

        setValidated(true);
        formVerifyHomeInspection(homeOwnerEmail, homeInspectionAccessCode);
    }

    const handleVerifyAnotherHomeInspection = (e) => {
        e.preventDefault();
        setHomeOwnerEmail('');
        setHomeInspectionAccessCode('');
        setHomeInspectionStatus('');
        setActiveIndex(0);
    }

    return (
        <div>
            <div class="row">
                <h2>Verify Home Assessment <FontAwesomeIcon
                    data-tooltip-id="view-home-inspection-form-tooltip"
                    data-tooltip-content="Enter your email address and access code to verify your home assessment request."
                    size='xs'
                    icon={faCircleQuestion}
                />
                <Tooltip id="view-home-inspection-form-tooltip" /></h2>
                {
                    activeIndex === 0 ? 
                    <VerifyHomeInspectionForm
                        homeOwnerEmail={homeOwnerEmail}
                        homeInspectionAccessCode={homeInspectionAccessCode}
                        setHomeOwnerEmail={setHomeOwnerEmail}
                        setHomeInspectionAccessCode={setHomeInspectionAccessCode}
                        handleSubmit={handleSubmit}
                        validated={validated}
                    />
                :
                    <HomeInspectionVerifyReport
                        homeInspectionStatus={homeInspectionStatus}
                        homeInspectionAccessCode={homeInspectionAccessCode}
                        handleViewHomeInspection={handleViewHomeInspection}
                        handleVerifyAnotherHomeInspection={handleVerifyAnotherHomeInspection}
                    />
                }
            </div>
        </div>
    );
}

// Rendered when activeIndex is 0
function VerifyHomeInspectionForm({
    setHomeOwnerEmail,
    setHomeInspectionAccessCode,
    handleSubmit,
    validated
}){
    return(
        <div class="col">
            <div class="form-container">
                <h4>Verify Home Assessment Form</h4>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                        <FormGroupInput
                          controlId="homeOwnerEmail"
                          label="Email Address"
                          required={true}
                          type="email"
                          invalidFeedback="Must provide a valid email."
                          onChange={(e) => setHomeOwnerEmail(e.target.value)}
                        />
                    </Row>
                    <Row>
                        <FormGroupInput
                            controlId="homeInspectionAccessCode"
                            label="Access Code"
                            required={true}
                            onChange={(e) => setHomeInspectionAccessCode(e.target.value)}
                            type="text"
                            invalidFeedback="Please enter an access code that is alphabetical characters only and 6 characters long."
                            pattern="^[a-zA-Z]{6}$"
                        />
                    </Row>
                    <Row>
                        <Form.Group class="form-group">
                            <Button
                                className="btn-standard margin-right-10"
                                type="submit">Verify
                            </Button>
                        </Form.Group>
                    </Row>
                </Form>
            </div>
        </div>
    );
}

// Rendered when activeIndex is 1
function HomeInspectionVerifyReport({
    homeInspectionStatus,
    homeInspectionAccessCode,
    handleViewHomeInspection,
    handleVerifyAnotherHomeInspection
}){
    return(
        <div class="col">
            <div class="form-container">
                <h4>Home Assessment Status</h4>
                <div class="row gy-2 gx-1">
                    <div class="form-group">
                        <FormControlInput label="Status" name="homeInspectionStatus" type="text"
                            value={homeInspectionStatus}
                            disabled={true} />
                        <FormControlInput label="Access Code" name="homeInspectionAccessCode" type="text"
                            value={homeInspectionAccessCode}
                            disabled={true} />
                    </div>
                    <div class="form-group">
                        <p>To view your home assessment, record your access code and browse to: </p>
                        <button className="btn-standard margin-right-10"
                                type="submit"
                                onClick={handleViewHomeInspection}>View Home Assessment</button>
                        <button className='btn-standard margin-right-10'
                                type='submit'
                                onClick={handleVerifyAnotherHomeInspection}>Verify Another Home Assessment</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
