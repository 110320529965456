import React, { useEffect, useState, useLayoutEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Module from "./Module";
import api from "../../../shared/utils/api";
import _ from "lodash";
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { ERROR_MESSAGE_DURATION } from "../../../shared/Constants";
import { AiOutlineSave } from "react-icons/ai";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";


const clamp = (value) => Math.max(0, value);

const isBetween = (value, floor, ceil) => value >= floor && value <= ceil;

// hooks
const useScrollspy = (ids, offset = 0) => {
  const [activeId, setActiveId] = useState("");

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;

      const position = ids
        .map((id) => {
          const element = document.getElementById(id);

          if (!element) return { id, top: -1, bottom: -1 };

          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scroll - offset);
          const bottom = clamp(rect.bottom + scroll - offset);

          return { id, top, bottom };
        })
        .find(({ top, bottom }) => isBetween(scroll, top, bottom));

      setActiveId(position?.id || "");
    };

    listener();

    window.addEventListener("resize", listener);
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.removeEventListener("scroll", listener);
    };
  }, [ids, offset]);

  return activeId;
};

function AuditForm() {
  const [audit, setAudit] = useState({});
  const [auditID, setAuditID] = useState(0);
  const [homeInspectionStatus, setHomeInspectionStatus] = useState("");

  const navigate = useNavigate();

  const modIDs =
    audit.auditModules?.map((module) => "mod-" + module.auditModuleID) || [];

  const activeID = useScrollspy(modIDs);

  let location = useLocation();
  let state = location.state;

  const params = useParams();

  const { id: homeInspectionID } = params;

  const setupAuditFormData = (auditForm, auditResults) => {
    return {
      auditID: auditForm.auditID,
      auditTitle: auditForm.auditTitle,
      auditModules: auditForm.auditModules.map((module) => {
        return {
          auditModuleID: module.auditModuleID,
          moduleTitle: module.moduleTitle,
          hexCode: module.hexCode,
          auditQuestions: module.auditQuestions?.map((auditQuestion) => {
            return {
              auditQuestionID: auditQuestion.auditQuestionID,
              question: auditQuestion.question,
              auditQuestionOptions: auditQuestion.auditQuestionOptions?.map(
                (answer) => {
                  return {
                    auditQuestionOptionID: answer.auditQuestionOptionID,
                    header: answer.header,
                    option: answer.option,
                    sortOrder: answer.sortOrder,
                    selected: _.includes(
                      auditResults.questionOptionIDs,
                      answer.auditQuestionOptionID,
                    ),
                  };
                },
              ),
              defaultComment: auditQuestion.defaultComment,
              comment:
                _.find(
                  auditResults.comments,
                  (comment) =>
                    comment.questionID === auditQuestion.auditQuestionID,
                )?.comment || "",
              homeOwnerComment: _.find(
                auditResults.comments,
                (comment) =>
                  comment.questionID === auditQuestion.auditQuestionID,
              )?.homeOwnerComment,
              supervisorComment: _.find(
                auditResults.comments,
                (comment) =>
                  comment.questionID === auditQuestion.auditQuestionID,
              )?.supervisorComment,
              supervisorCommentStatus: _.find(
                auditResults.comments,
                (comment) =>
                  comment.questionID === auditQuestion.auditQuestionID,
              )?.supervisorCommentStatus,
              homeInspectionCommentID: _.find(
                auditResults.comments,
                (comment) =>
                  comment.questionID === auditQuestion.auditQuestionID,
              )?.homeInspectionCommentID,
              requiresAction:
                _.find(
                  auditResults.comments,
                  (comment) =>
                    comment.questionID === auditQuestion.auditQuestionID,
                )?.requiresAction || false,
              images:
                _.filter(
                  auditResults.images,
                  (image) => image.questionID === auditQuestion.auditQuestionID,
                ) || [],
            };
          }),
        };
      }),
    };
  };

  const setCommentCallback = (questionID, comment) => {
    const auditModuleIndex = getAuditModuleIndexByQuestionID(questionID);
    const questionOptions = audit.auditModules[auditModuleIndex].auditQuestions;
    const auditQuestionIndex = _.findIndex(
      questionOptions,
      (question) => question.auditQuestionID === questionID,
    );

    const updated = _.set(
      audit,
      `auditModules[${auditModuleIndex}].auditQuestions[${auditQuestionIndex}].comment`,
      comment,
    );
    setAudit(updated);
  };

  const setSupervisorCommentCallback = (questionID, comment) => {
    const auditModuleIndex = getAuditModuleIndexByQuestionID(questionID);
    const questionOptions = audit.auditModules[auditModuleIndex].auditQuestions;
    const auditQuestionIndex = _.findIndex(
      questionOptions,
      (question) => question.auditQuestionID === questionID,
    );

    const updated = _.set(
      audit,
      `auditModules[${auditModuleIndex}].auditQuestions[${auditQuestionIndex}].supervisorComment`,
      comment,
    );
    setAudit(updated);
  };

  const setRequiresActionCallback = (questionID, requiresAction) => {
    const auditModuleIndex = getAuditModuleIndexByQuestionID(questionID);
    const questionOptions = audit.auditModules[auditModuleIndex].auditQuestions;
    const auditQuestionIndex = _.findIndex(
      questionOptions,
      (question) => question.auditQuestionID === questionID,
    );

    const updated = _.set(
      audit,
      `auditModules[${auditModuleIndex}].auditQuestions[${auditQuestionIndex}].requiresAction`,
      requiresAction,
    );
    setAudit(updated);
  };

  const setQuestionOptionsCallback = (questionID, answerIDs) => {
    const auditModuleIndex = getAuditModuleIndexByQuestionID(questionID);

    const questionOptions = audit.auditModules[auditModuleIndex].auditQuestions;
    const auditQuestionIndex = _.findIndex(
      questionOptions,
      (question) => question.auditQuestionID === questionID,
    );
    const aqo = questionOptions[auditQuestionIndex].auditQuestionOptions;

    aqo.forEach((answer) => {
      answer.selected = answerIDs.includes(answer.auditQuestionOptionID);
    });

    const updated = _.set(
      audit,
      `auditModules[${auditModuleIndex}].auditQuestions[${auditQuestionIndex}].auditQuestionOptions`,
      aqo,
    );

    setAudit(updated);
  };

  const removeImageCallback = (questionID, imageID) => {
    api.delete("/home-inspection-images/" + imageID).then(
      () => {
        toast.success("Image removed successfully", { autoClose: 2000 });
        removeImageFromAuditData(questionID, imageID);
      },
      (error) => {
        toast.error("Error removing image: " + error, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
        // TODO: how do we make the image preview show up again?
      },
    );
  };

  const removeImageFromAuditData = (questionID, imageID) => {
    const auditModuleIndex = getAuditModuleIndexByQuestionID(questionID);
    const questionOptions = audit.auditModules[auditModuleIndex].auditQuestions;
    const auditQuestionIndex = _.findIndex(
      questionOptions,
      (question) => question.auditQuestionID === questionID,
    );

    const newImgs = audit.auditModules[auditModuleIndex].auditQuestions[
      auditQuestionIndex
    ].images.filter((image) => image.imageID !== imageID);

    var updated = _.set(
      audit,
      `auditModules[${auditModuleIndex}].auditQuestions[${auditQuestionIndex}images`,
      newImgs,
    );
    setAudit(updated);
  };

  const formatDataForSubmit = () => {
    var inspectionResults = audit.auditModules.map((module) =>
      module.auditQuestions?.map((question) => {
        return {
          questionID: parseInt(question.auditQuestionID),
          questionOptionIDs: question.auditQuestionOptions
            ?.filter((option) => option.selected)
            .map((option) => option.auditQuestionOptionID),
          comment: question.comment,
          requiresAction: question.requiresAction,
          supervisorComment: question.supervisorComment
        };
      }),
    );

    inspectionResults = _.flatten(inspectionResults).filter(
      (result) => result && result.questionOptionIDs !== undefined,
    );
    return {
      homeInspectionID: parseInt(homeInspectionID),
      results: inspectionResults,
    };
  };

  const inspectionCompleted =
    homeInspectionStatus === "Completed" ||
    homeInspectionStatus === "Approval Required" ||
    homeInspectionStatus === "Changes Requested" ||
    homeInspectionStatus === "Changes Submitted";

  const saveInspection = (callBack) => {
    const formatted = formatDataForSubmit();
    api.post("/home-inspection-results/submit", formatted).then(
      () => {
        if (callBack) {
          callBack();
        } else {
          toast.success("Assessment saved successfully");
        }
      },
      (error) => {
        toast.error("Error saving assessment: " + error, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      },
    );
  };

  const completeInspection = () => {
    saveInspection(submitApprovalRequired);
  };

  const submitApprovalRequired = () => {
    api
      .post("/home-inspection-approval-required", {
        targetHomeInspectionID: parseInt(homeInspectionID),
      })
      .then(
        (response) => {
          toast.success("Assessment completed successfully");
          if (response) {
            setHomeInspectionStatus(response.homeInspectionStatus);
            navigate("/home-inspections");
          }
        },
        (error) => {
          toast.error("Error completing assessment: " + error, {
            autoClose: ERROR_MESSAGE_DURATION,
          });
        },
      );
  };

  const promptCompleteInspection = (e) => {
    e.preventDefault();
    // Confirm the user wants to complete the inspection

    const formData = formatDataForSubmit();
    const answeredQuestionCount = formData.results.filter(
      (f) => f.questionOptionIDs.length,
    ).length;
    const questionsRequiringAnswerCount = audit.auditModules.reduce(
      (acc, module) => {
        return (
          acc +
          module.auditQuestions.filter(
            (q) => q.auditQuestionOptions && q.auditQuestionOptions.length,
          ).length
        );
      },
      0,
    );

    if (answeredQuestionCount !== questionsRequiringAnswerCount) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="confirm-dialog">
              <h3>Missing Answers</h3>
              <p>
                Not all questions have an answer. Please fill them in before
                submitting for approval.
              </p>
              <button
                className="btn btn-standard float-right"
                onClick={() => {
                  onClose();
                }}
              >
                Ok
              </button>
            </div>
          );
        },
      });
      return;
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-dialog">
            <h3>Complete Assessment</h3>
            <p>Are you sure you would like to complete this assessment?</p>
            <button
              className="btn btn-standard float-right"
              onClick={() => {
                completeInspection();
                onClose();
              }}
            >
              Complete
            </button>
            <button
              className="btn btn-standard secondary margin-right-10 float-right"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
    });
  };


  const getAuditModuleIndexByQuestionID = (questionID) => {
    return _.findIndex(audit.auditModules, (module) => {
      return (
        _.findIndex(module.auditQuestions, (question) => {
          return question.auditQuestionID === questionID;
        }) > -1
      );
    });
  };

  // keypress listener to close fullscreen image
  const handleKeyPress = (event) => {
    if (event.key === "Escape") {
      closeFullScreen();
    }
  };

  const closeFullScreen = () => {
    var fullpage = document.getElementById("fullpage");
    if (fullpage) {
      fullpage.style.display = "none";
    }
  };

  useEffect(() => {
    if (auditID === 0) return;
    if (parseInt(homeInspectionID) === 0) return;

    const promiseAuditInfo = new Promise((resolve, reject) => {
      api.get("/audit-form/" + auditID).then(
        (response) => {
          if (response) {
            resolve(response);
          }
        },
        (error) => {
          reject(error);
        },
      );
    });

    const promiseAuditResults = new Promise((resolve, reject) => {
      api.get("/home-inspection-results/" + homeInspectionID).then(
        (response) => {
          if (response) {
            resolve(response);
          }
        },
        (error) => {
          reject(error);
        },
      );
    });

    const fetchData = () => {
      Promise.all([promiseAuditInfo, promiseAuditResults]).then((values) => {
        var auditInfo = values[0];
        var auditResults = values[1];

        var tempAudit = setupAuditFormData(auditInfo, auditResults);
        console.log("tempAudit", tempAudit);
        setAudit(tempAudit);
      });
    };

    const fetchHomeInspectionStatus = () => {
      api.get("/home-inspection/" + homeInspectionID).then(
        (response) => {
          if (response) {
            setHomeInspectionStatus(response.homeInspectionStatus);
          }
        },
        (error) => {
          toast.error("Error fetching home assessment status: " + error, {
            autoClose: ERROR_MESSAGE_DURATION,
          });
        },
      );
    };

    fetchData();
    fetchHomeInspectionStatus();
  }, [auditID, homeInspectionID]);

  useEffect(() => {
    if (state) {
      setAuditID(state.auditID);
    }

    if (!state) {
      // TODO: go back to previous page or look up audit ID from API
    }

    window.onkeydown = handleKeyPress;
  }, []);

  return (
    <div>
      <h2 className="mb-8 mt-2">{audit.auditTitle} Assessment</h2>
      <Row>
        <Col md={9}>
          {audit.auditModules &&
            audit.auditModules.map((module, index) => (
              console.log(module),
              <Module
                key={`mod-${module.auditModuleID}`}
                moduleID={module.auditModuleID}
                moduleTitle={module.moduleTitle}
                moduleQuestions={module.auditQuestions}
                hexCode={module.hexCode}
                setCommentCallback={setCommentCallback}
                setSupervisorCommentCallback={setSupervisorCommentCallback}
                setQuestionOptionsCallback={setQuestionOptionsCallback}
                setRequiresActionCallback={setRequiresActionCallback}
                removeImageCallback={removeImageCallback}
                auditID={auditID}
                homeInspectionID={homeInspectionID}
                className={index !== 0 ? "mt-[75px]" : ""}
              />
            ))}
        </Col>
        <Col md={3}>
          <div id="inspection-minimap" className="bottom-[20px] left-0 md:left-auto px-4 w-full md:bottom-auto">
            <div className="hidden md:block">
            <h4>Navigation</h4>
            {audit.auditModules &&
              audit.auditModules.map((module) => (
                <div key={`mod-${module.auditModuleID}`}>
                  <a
                    href={"#" + module.moduleTitle}
                    className={
                      activeID === "mod-" + module.auditModuleID ? "active" : ""
                    }
                  >
                    {module.moduleTitle}
                  </a>
                </div>
              ))}
            </div>
            <div className="flex w-full justify-between md:block">
            <button
              className="btn btn-standard btn-black  margin-right-10 margin-top-20"
              onClick={() => saveInspection()}
            >
             <AiOutlineSave className="mr-1.5 inline-block" /> Save
            </button>
            {!inspectionCompleted && (
              <button
                className="btn btn-standard margin-top-20"
                onClick={promptCompleteInspection}
              >
                <IoIosCheckmarkCircleOutline className="mr-1.5 inline-block" /> Submit for review
              </button>
            )}
            </div>
          </div>
        </Col>
      </Row>
      <div id="fullpage" onClick={closeFullScreen}></div>
    </div>
  );
}

export default AuditForm;
