import React, { useEffect, useState } from "react";
import api from "../../shared/utils/api";
import FormGroupInput from "../../shared/components/FormGroupInput";
import FormGroupProvinceDropdownInput from "../../shared/components/FormGroupProvinceDropdownInput";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { ERROR_MESSAGE_DURATION } from "../../shared/Constants";
import PostalCodeInput from "../../shared/components/PostalCodeInput";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { isUserAdmin } from "../../shared/utils/authToken";

export default function RequestHomeInspection() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [validated, setValidated] = useState(false);
  const [homeOwnerEmail, setHomeOwnerEmail] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [homeOwnerPhoneNumber, setHomeOwnerPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("BC");
  const [postalCode, setPostalCode] = useState("");
  const [territories, setTerritories] = useState([]);
  const [selectedTerritoryId, setSelectedTerritoryId] = useState(1);

  const requestInspection = (homeOwnerEmail) => {
    // TODO: input sanitization
    api
      .post("/home-inspections", {
        //TODO: Audit ID will come from what type of Audit the user is requesting
        // since we don't have that we are going to hardcode it to 1
        auditID: 1,
        homeInspectionStatus: "Requested",
        homeOwnerEmailAddress: homeOwnerEmail,
        homeOwnerPhoneNumber:
          homeOwnerPhoneNumber === "" ? null : "+1" + homeOwnerPhoneNumber,
        address: address,
        city: city,
        province: province,
        postalCode: postalCode,
        territoryId: +selectedTerritoryId,
      })
      .then(
        (response) => {
          setAccessCode(response.homeInspectionAccessCode);
          setActiveIndex(1);
          toast.success("Your home assessment request was been successful!", {
            autoClose: 2000,
          });
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        },
      );
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const agency = params.get('agency');
    console.log(agency);
    if (agency) {
      const matchingTerritory = territories.find(territory => territory.name === agency);
      if (matchingTerritory) {
        setSelectedTerritoryId(matchingTerritory.id);
      }
    }
  }, [territories]);
  

  const handleTerritoryChange = (e) => {
    setSelectedTerritoryId(e.target.value);
  };

  useEffect(() => {
    const requestUrl = "/territories-for-public";
    api.get(requestUrl).then(
      (response) => {
        setTerritories(response);
      },
      (error) => {
        toast.error("Failed to load territories. Error: " + error, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      },
    );
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return; // Return if validation failed
    }

    setValidated(true);

    requestInspection(homeOwnerEmail);
  };

  const handleViewHomeInspection = (e) => {
    e.preventDefault();
    window.location.href = "/view-home-inspection";
  };

  const handleRequestAnotherHomeInspection = (e) => {
    e.preventDefault();
    setValidated(false);
    setHomeOwnerEmail("");
    setHomeOwnerPhoneNumber("");
    setAddress("");
    setCity("");
    setProvince("");
    setPostalCode("");
    setActiveIndex(0);
  };

  return (
    <div>
      <h2>Request A Home Assessment</h2>
      <p>Enter your information below to request a home assessment.</p>
      {activeIndex === 0 ? (
        <HomeInspectionRequestForm
          homeOwnerEmail={homeOwnerEmail}
          setHomeOwnerEmail={setHomeOwnerEmail}
          homeOwnerPhoneNumber={homeOwnerPhoneNumber}
          setHomeOwnerPhoneNumber={setHomeOwnerPhoneNumber}
          address={address}
          setAddress={setAddress}
          city={city}
          setCity={setCity}
          province={province}
          setProvince={setProvince}
          postalCode={postalCode}
          setPostalCode={setPostalCode}
          handleSubmit={handleSubmit}
          validated={validated}
          handleTerritoryChange={handleTerritoryChange}
          selectedTerritoryId={selectedTerritoryId}
          territories={territories}
        />
      ) : (
        <HomeInspectionRequestReport
          homeOwnerEmail={homeOwnerEmail}
          accessCode={accessCode}
          handleViewHomeInspection={handleViewHomeInspection}
          handleRequestAnotherHomeInspection={
            handleRequestAnotherHomeInspection
          }
        />
      )}
    </div>
  );
}

// Rendered when activeIndex is 0
function HomeInspectionRequestForm({
  setHomeOwnerEmail,
  setHomeOwnerPhoneNumber,
  setAddress,
  setCity,
  province,
  postalCode,
  setProvince,
  setPostalCode,
  handleSubmit,
  validated,
  handleTerritoryChange,
  selectedTerritoryId,
  territories,
}) {
  return (
    <div className="col">
      <div className="form-container">
        {/* <h4>Request Home Assessment Form</h4> */}
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="pt-3"
        >
          <Row>
          <Form.Group as={Col} controlId="territory" className="form-group">
  {selectedTerritoryId != 1 && selectedTerritoryId && territories.some(territory => territory.id === selectedTerritoryId) ? (
    <input type="hidden" name="territory" value={selectedTerritoryId} />
  ) : (
    <>
      <Form.Label className="font-bold">What region do you live in?</Form.Label>
      <Form.Select
        className="form-select"
        onChange={handleTerritoryChange}
        value={selectedTerritoryId}
      >
        <option value="" disabled>Please select</option>
        {territories.map((territory) => (
          <option key={territory.id} value={territory.id}>
            {territory.name}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        Must select one of the territories listed.
      </Form.Control.Feedback>
    </>
  )}
</Form.Group>
          </Row>
          <Row className="mb-3">
            <FormGroupInput
              controlId="homeOwnerEmail"
              label="Email Address"
              required
              type="email"
              invalidFeedback="Must provide a valid email."
              onChange={(e) => setHomeOwnerEmail(e.target.value)}
            />
          </Row>
          <Row className="mb-3">
            <FormGroupInput
              controlId="homeOwnerPhoneNumber"
              label="Phone Number (Optional)"
              type="tel"
              invalidFeedback="Must provide a phone number in the format 123-456-7890 or 1234567890."
              onChange={(e) => setHomeOwnerPhoneNumber(e.target.value)}
              pattern="(\d{3}-?\d{3}-?\d{4})|(\d{10})"
            />
          </Row>
          <Row className="mb-3">
            <FormGroupInput
              controlId="address"
              label="Address"
              required
              type="text"
              invalidFeedback="Must provide an address with allowed special characters .:-_', and allowed spaces."
              onChange={(e) => setAddress(e.target.value)}
              pattern="^[a-zA-Z0-9 .:,-_']+$"
            />
          </Row>
          <Row className="mb-3">
            <FormGroupInput
              controlId="city"
              label="City"
              required
              type="text"
              invalidFeedback="Must provide a city with allowed special characters .:-_', and allowed spaces."
              onChange={(e) => setCity(e.target.value)}
              pattern="^[a-zA-Z0-9 .:,-_']+$"
            />
          </Row>
          <Row className="mb-3">
            <FormGroupProvinceDropdownInput
              value={province}
              onChange={(e) => setProvince(e.target.value)}
              required
              placeHolderRequired
            />
          </Row>
          <Row className="mb-3">
            <PostalCodeInput
              controlId="postalCode"
              label="Postal Code"
              required
              type="text"
              invalidFeedback="Must provide a postal code matching the A1A 1A1 pattern."
              value={postalCode}
              onChange={(e) => setPostalCode(e)}
              pattern="^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$"
            />
          </Row>
          <Row className="mb-3">
            <Form.Group class="form-group">
              <Button className="btn-standard margin-right-10" type="submit">
                Request Home Assessment
              </Button>
            </Form.Group>
          </Row>
        </Form>
      </div>
    </div>
  );
}

// Rendered when activeIndex is 1
function HomeInspectionRequestReport({
  homeOwnerEmail,
  accessCode,
  handleViewHomeInspection,
  handleRequestAnotherHomeInspection,
}) {
  return (
    <div className="col">
      <div className="form-container">
        <h4>Home Assessment Request Report</h4>
        <p>Your home assessment request has been submitted!</p>
        <p>
          An email has been sent to the email address {homeOwnerEmail} with the
          access code {accessCode}.
        </p>
        <p>
          Use this access code with your email address to view your home
          assessment.
        </p>
        <Button
          className="btn-standard margin-right-10"
          type="submit"
          onClick={handleViewHomeInspection}
        >
          View Home Assessment
        </Button>
        <Button
          className="btn-standard margin-right-10"
          type="submit"
          onClick={handleRequestAnotherHomeInspection}
        >
          Request Another Home Assessment
        </Button>
      </div>
    </div>
  );
}
