import * as React from "react";
import { useState, useEffect } from "react";
import api from "../../shared/utils/api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FormGroupInput from "../../shared/components/FormGroupInput";
import FormGroupProvinceDropdownInput from "../../shared/components/FormGroupProvinceDropdownInput";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { ERROR_MESSAGE_DURATION } from "../../shared/Constants";
import PostalCodeInput from "../../shared/components/PostalCodeInput";
import { getLoggedInUser, isUserAdmin } from "../../shared/utils/authToken";
import { MdOutlineArrowOutward } from "react-icons/md";
import { format } from "date-fns";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function HomeInspection() {
  const [dateTime, setDateTime] = useState(null);
  const [validated, setValidated] = useState(false);
  const [homeInspectionStatus, setHomeInspectionStatus] = useState("");
  const [auditID, setAuditID] = useState(0);
  const [homeInspectionAccessCode, setHomeInspectionAccessCode] = useState("");
  const [homeOwnerEmailAddress, setHomeOwnerEmailAddress] = useState("");
  const [homeOwnerPhoneNumber, setHomeOwnerPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [parcelID, setParcelID] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [assignedInspectorID, setAssignedInspectorID] = useState("");
  const [assignedInspectorFirstName, setAssignedInspectorFirstName] =
    useState("");
  const [assignedInspectorLastName, setAssignedInspectorLastName] =
    useState("");
  const [assignedInspectorNote, setAssignedInspectorNote] = useState("");
  const [changesRequestedNote, setChangesRequestedNote] = useState("");
  const [changesSubmittedNote, setChangesSubmittedNote] = useState("");
  const [cancellationNote, setCancellationNote] = useState("");
  const [auditPresignedURL, setAuditPresignedURL] = useState("");
  const [auditTitle, setAuditTitle] = useState("");
  const [territories, setTerritories] = useState([]);
  const [selectedTerritoryId, setSelectedTerritoryId] = useState("");

  const handleTerritoryChange = (e) => {
    setSelectedTerritoryId(e.target.value);
  };

  const navigate = useNavigate();
  const params = useParams();

  const { id } = params;

  useEffect(() => {
    const requestUrl = isUserAdmin() ? "/territories" : "/territories-for-user";
    api.get(requestUrl).then(
      (response) => {
        setTerritories(response);
      },
      (error) => {
        toast.error("Failed to load territories. Error: " + error, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      }
    );
  }, []);

  useEffect(() => {
    const fetchHomeInspection = () => {
      api.get("/home-inspection/" + id).then(
        (response) => {
          setHomeInspectionStatus(response.homeInspectionStatus);
          setAuditID(response.auditID);
          setHomeInspectionAccessCode(response.homeInspectionAccessCode);
          setHomeOwnerEmailAddress(response.homeOwnerEmailAddress);
          setHomeOwnerPhoneNumber(response.homeOwnerPhoneNumber);
          setAddress(response.address);
          setCity(response.city);
          setProvince(response.province);
          setPostalCode(response.postalCode);
          setParcelID(response.parcelID);
          setLatitude(response.latitude);
          setLongitude(response.longitude);
          setAssignedInspectorID(response.assignedInspectorID);
          setAssignedInspectorFirstName(response.assignedInspectorFirstName);
          setAssignedInspectorLastName(response.assignedInspectorLastName);
          setAssignedInspectorNote(response.assignedInspectorNote);
          setChangesRequestedNote(response.changesRequestedNote);
          setChangesSubmittedNote(response.changesSubmittedNote);
          setCancellationNote(response.cancellationNote);
          setSelectedTerritoryId(response.territoryId);
          setDateTime(response.scheduledDate ? new Date(response.scheduledDate) : response.scheduledDate);
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        }
      );
    };

    fetchHomeInspection();
  }, [id]);

  useEffect(() => {
    if (auditID === 0) {
      return;
    }
    const fetchAudit = () => {
      api.get("/audits/" + auditID).then(
        (response) => {
          setAuditPresignedURL(response.presignedURL);
          setAuditTitle(response.auditTitle);
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        }
      );
    };

    fetchAudit();
  }, [auditID]);

  const updateHomeInspection = () => {
    console.log(dateTime);
    api
      .post("/home-inspections", {
        homeInspectionID: Number(id),
        auditID: parseInt(auditID),
        homeInspectionStatus: homeInspectionStatus,
        homeOwnerEmailAddress: homeOwnerEmailAddress,
        homeOwnerPhoneNumber:
          homeOwnerPhoneNumber === "" || homeOwnerPhoneNumber === null
            ? null
            : homeOwnerPhoneNumber.replace("-", ""),
        address: address,
        city: city,
        province: province,
        postalCode: postalCode,
        parcelID: parcelID,
        latitude: latitude ? parseFloat(latitude) : null,
        longitude: longitude ? parseFloat(longitude) : null,
        assignedInspectorID: assignedInspectorID ? assignedInspectorID : null,
        assignedInspectorNote: assignedInspectorNote
          ? assignedInspectorNote
          : null,
        changesRequestedNote: changesRequestedNote
          ? changesRequestedNote
          : null,
        changesSubmittedNote: changesSubmittedNote
          ? changesSubmittedNote
          : null,
        cancellationNote: cancellationNote ? cancellationNote : null,
        territoryId: +selectedTerritoryId,
        scheduledDate: dateTime ? new Date(dateTime).toISOString() : null,
      })
      .then(
        (response) => {
          setAuditID(response.auditID);
          setHomeInspectionStatus(response.homeInspectionStatus);
          setHomeInspectionAccessCode(response.homeInspectionAccessCode);
          setHomeOwnerEmailAddress(response.homeOwnerEmailAddress);
          setHomeOwnerPhoneNumber(response.homeOwnerPhoneNumber);
          setAddress(response.address);
          setCity(response.city);
          setProvince(response.province);
          setPostalCode(response.postalCode);
          // handle nullable fields so form gets updated without needing a refresh
          response.parcelID ? setParcelID(response.parcelID) : setParcelID("");
          response.latitude ? setLatitude(response.latitude) : setLatitude("");
          response.longitude
            ? setLongitude(response.longitude)
            : setLongitude("");
          response.assignedInspectorID
            ? setAssignedInspectorID(response.assignedInspectorID)
            : setAssignedInspectorID("");
          response.assignedInspectorNote
            ? setAssignedInspectorNote(response.assignedInspectorNote)
            : setAssignedInspectorNote("");
          response.changesRequestedNote
            ? setChangesRequestedNote(response.changesRequestedNote)
            : setChangesRequestedNote("");
          response.changesSubmittedNote
            ? setChangesSubmittedNote(response.changesSubmittedNote)
            : setChangesSubmittedNote("");
          response.cancellationNote
            ? setCancellationNote(response.cancellationNote)
            : setCancellationNote("");
          toast.success("Successfully saved changes to assessment", {
            autoClose: 2000,
          });
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        }
      );
  };

  const { useRef } = React;
  const dateRef = useRef();

  const handleDateClick = () => {
    dateRef.current.showPicker();
  };

  const submitHomeInspectionStart = () => {
    api
      .post("/start-home-inspection", {
        targetHomeInspectionID: Number(id),
      })
      .then(
        (response) => {
          setHomeInspectionStatus("In Progress");
          toast.success("Successfully started assessment", {
            autoClose: 2000,
          });
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        }
      );
  };

  const submitHomeInspectionComplete = () => {
    api
      .post("/submit-home-inspection", {
        targetHomeInspectionID: Number(id),
      })
      .then(
        (response) => {
          if (response != null) {
            setHomeInspectionStatus(response.homeInspectionStatus);
          }
          toast.success("Assessment has been approved!", {
            autoClose: 2000,
          });
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        }
      );
  };

  const handlePromptDelete = (e) => {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div class="confirm-dialog">
            <h3>Delete Assessment</h3>
            <p>Are you sure you would like to delete this assessment?</p>
            <button
              class="btn btn-danger float-right"
              onClick={() => {
                handleDelete();
                onClose();
              }}
            >
              Delete
            </button>
            <button
              class="btn btn-standard secondary margin-right-10 float-right"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
    });
  };

  const handleDelete = () => {
    api.delete("/home-inspections/" + Number(id)).then(
      (response) => {
        toast.success("Assessment has been deleted!", {
          autoClose: 2000,
        });
        navigate("/home-inspections");
      },
      (error) => {
        toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return; // Return if validation failed
    }

    setValidated(true);

    updateHomeInspection();
  };

  const handleAssign = (e) => {
    e.preventDefault();
    navigate("/home-inspection/" + id + "/assign", {
      state: {
        homeInspectionId: id,
        assignedInspectorNote: assignedInspectorNote,
      },
    });
  };

  const handleStartInspection = (e) => {
    e.preventDefault();
    submitHomeInspectionStart();
  };

  const handleComplete = (e) => {
    e.preventDefault();
    submitHomeInspectionComplete();
  };

  const handleCancelInspection = (e) => {
    e.preventDefault();
    navigate("/home-inspection/" + id + "/cancel", {
      state: { homeInspectionId: id, cancellationNote: cancellationNote },
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/home-inspections");
  };

  const handleNavigateToAudit = (e) => {
    e.preventDefault();
    navigate("/home-inspection/" + id + "/inspection", { state: { auditID } });
  };
  const user = getLoggedInUser();

  const mainContent = () => {
    return (
      <div>
        <div class="row">
          <div class="col-md-8">
            <div class="form-container !py-2.5">
              <div className="flex justify-between items-center mb-3">
                <h4 className="flex-order-0 md:flex-order-1">
                  Assessment Info for {address}, {city}
                </h4>
                <button
                  class="btn btn-standard flex-order-1 md:flex-order-0"
                  onClick={handleNavigateToAudit}
                >
                  View Assessment{" "}
                  <MdOutlineArrowOutward className="ml-2 h-4 w-4 -mt-0.5 inline-block" />
                </button>
              </div>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <Col
                    md={homeInspectionStatus === "Approval Required" ? 8 : 12}
                  >
                    <FormGroupInput
                      label="Assessment Status"
                      name="homeInspectionStatus"
                      type="text"
                      value={homeInspectionStatus}
                      onChange={(e) => setHomeInspectionStatus(e.target.value)}
                      disabled
                    />
                  </Col>
                  {homeInspectionStatus === "Approval Required" &&
                    user.userRoleID !== 4 && (
                      <Col md={4} className="mt-4">
                        <Button
                          variant="primary"
                          className="mt-2 btn-success"
                          onClick={handleComplete}
                        >
                          Approve
                        </Button>
                      </Col>
                    )}
                </Row>
                <Row>
                  <Form.Group class="form-group">
                    {(homeInspectionStatus == "Requested" ||
                      homeInspectionStatus == "Request Verified" ||
                      homeInspectionStatus == "Assigned") && (
                      <Button
                        className="btn-standard  margin-right-10"
                        type="button"
                        onClick={handleStartInspection}
                      >
                        Start
                      </Button>
                    )}
                    <Button
                      className="btn-standard btn-outline margin-right-10 !hover:bg-black !hover:text-primary"
                      type="button"
                      onClick={handleAssign}
                    >
                      Assign
                    </Button>

                    <Button
                      className="btn-standard btn-cancel margin-right-10"
                      type="button"
                      onClick={handleCancelInspection}
                    >
                      Cancel Assessment
                    </Button>
                    {isUserAdmin() && (
                      <button
                        className="btn link float-right font-bold"
                        onClick={handlePromptDelete}
                      >
                        Delete Assessment
                      </button>
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <FormGroupInput
                    label="Assessment Access Code"
                    name="homeInspectionAccessCode"
                    type="text"
                    value={homeInspectionAccessCode}
                    onChange={(e) =>
                      setHomeInspectionAccessCode(e.target.value)
                    }
                    disabled={true}
                  />
                </Row>
                <Row>
                  <FormGroupInput
                    label="Home Assessment Type"
                    name="auditTitle"
                    type="text"
                    value={auditTitle}
                    disabled={true}
                  />
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    controlId="territory"
                    className="form-group"
                  >
                    <Form.Label className="font-bold">Agency</Form.Label>
                    <Form.Select
                      className="form-select"
                      onChange={handleTerritoryChange}
                      value={selectedTerritoryId}
                    >
                      {territories.map((territory) => (
                        <option key={territory.id} value={territory.id}>
                          {territory.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Must select one of the territories listed.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <FormGroupInput
                    label="Home Owner Email Address"
                    name="homeOwnerEmailAddress"
                    type="email"
                    value={homeOwnerEmailAddress}
                    required={true}
                    onChange={(e) => setHomeOwnerEmailAddress(e.target.value)}
                    invalidFeedback="Please enter a valid email address."
                  />
                </Row>
                <Row>
                  <FormGroupInput
                    label="Home Owner Phone Number (Optional)"
                    name="homeOwnerPhoneNumber"
                    type="tel"
                    value={homeOwnerPhoneNumber}
                    required={false}
                    onChange={(e) => setHomeOwnerPhoneNumber(e.target.value)}
                    invalidFeedback="Phone number must be in the format +1123-456-7890 or +11234567890."
                    pattern="^(\+1)(\d{3}-?\d{3}-?\d{4})|(\d{10})"
                  />
                </Row>
                <Row>
                  <FormGroupInput
                    label="Address"
                    name="address"
                    type="text"
                    value={address}
                    required={true}
                    onChange={(e) => setAddress(e.target.value)}
                    invalidFeedback="Must provide an address with allowed special characters .;:, and allowed spaces."
                    pattern="^[a-zA-Z0-9 .:,-_']+$"
                  />
                </Row>
                <Row>
                  <FormGroupInput
                    controlId="city"
                    label="City"
                    type="text"
                    value={city}
                    required={true}
                    invalidFeedback="Must provide a city with allowed special characters .:-_', and allowed spaces."
                    onChange={(e) => setCity(e.target.value)}
                    pattern="^[a-zA-Z0-9 .:,-_']+$"
                  />
                </Row>
                <Row>
                  <FormGroupProvinceDropdownInput
                    value={province}
                    required={true}
                    onChange={(e) => setProvince(e.target.value)}
                    placeHolderRequired={false}
                  />
                </Row>
                <Row>
                  <PostalCodeInput
                    controlId="postalCode"
                    label="Postal Code"
                    required={true}
                    type="text"
                    invalidFeedback="Must provide a postal code matching the A1A 1A1 pattern."
                    value={postalCode}
                    onChange={(e) => setPostalCode(e)}
                    pattern="^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$"
                  />
                </Row>
                <Row>
                  <div className="mb-3">
                    <label
                      className="font-bold block mb-1"
                      htmlFor="assessmentDateTime"
                    >
                      Assessment Date and Time
                    </label>
                    <DatePicker
                      selected={dateTime}
                      onChange={(date) => setDateTime(date)}
                      showTimeSelect
                      dateFormat="Pp"
                      placeholderText="Select date and time"
                      className="w-full p-2 border rounded cursor-pointer"
                    />
                    {/* Hidden input to store the formatted value */}
                    <input
                      type="hidden"
                      name="assessmentDateTime"
                      value={
                        dateTime
                          ? format(dateTime, "yyyy-MM-dd'T'HH:mm:ss")
                          : ""
                      }
                    />
                  </div>
                </Row>
                <Row>
                  <FormGroupInput
                    label="Parcel ID"
                    name="parcelID"
                    type="text"
                    value={parcelID}
                    onChange={(e) => setParcelID(e.target.value)}
                    pattern="^\d{3}-\d{3}-\d{3}$"
                    invalidFeedback="Parcel ID must be in the format 123-456-789 or be unset."
                  />
                </Row>
                <Row>
                  <FormGroupInput
                    label="Latitude"
                    name="latitude"
                    type="text"
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                    invalidFeedback="Latitude must be a number between -90.000000 and 90.000000 or be unset."
                    pattern="^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$"
                  />
                </Row>
                <Row>
                  <FormGroupInput
                    label="Longitude"
                    name="longitude"
                    type="text"
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                    invalidFeedback="Longitude must be a number between -100.000000 and 100.000000 or be unset."
                    pattern="(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))"
                  />
                </Row>
                <Row>
                  <FormGroupInput
                    label="Assigned Assessor"
                    name="assignedInspectorID"
                    type="text"
                    value={
                      assignedInspectorFirstName && assignedInspectorLastName
                        ? assignedInspectorFirstName +
                          " " +
                          assignedInspectorLastName
                        : "Unassigned"
                    }
                    disabled={true}
                  />
                </Row>
                <Row>
                  <FormGroupInput
                    label="Assigned Assessor Note"
                    name="assignedInspectorNote"
                    type="text"
                    value={assignedInspectorNote}
                    disabled={true}
                  />
                </Row>
                <Row>
                  <FormGroupInput
                    label="Cancellation Note"
                    name="cancellationNote"
                    type="text"
                    value={cancellationNote}
                    disabled={true}
                  />
                </Row>
                <Row>
                  <Form.Group class="form-group">
                    <Button
                      className="btn-standard margin-right-10"
                      type="submit"
                    >
                      Save Edits
                    </Button>
                    <Button
                      className="btn-standard btn-cancel"
                      type="button"
                      onClick={handleCancel}
                    >
                      Cancel Edits
                    </Button>
                  </Form.Group>
                </Row>
              </Form>
            </div>
          </div>
          {/* <div class="col">
            <div class="form-container">
              <h4>Assessments</h4>
              <div>
                <div>
                <img
                  class="audit-splashscreen-preview"
                  src={auditPresignedURL}
                  onClick={handleNavigateToAudit}
                />
                  <h5>{auditTitle} Assessment</h5>
                 
                </div>
                
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  };

  return mainContent();
}

export default HomeInspection;
