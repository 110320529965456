export function generateUsername(email) {
    const atIndex = email.indexOf("@");
    if (atIndex === -1) {
        throw new Error("Invalid email format");
    }
    let localPart = email.substring(0, atIndex);

    // alphanum regex pattern
    const regexPattern = /^[a-zA-Z0-9 ]+$/;

    localPart = localPart.split("").filter(char => regexPattern.test(char)).join("");

    const randomSuffix = Math.floor(1000 + Math.random() * 9000); // random 4-digit number

    return `${localPart}${randomSuffix}`;
}