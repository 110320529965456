import React from 'react';

function CourseRegister(props) {
    return (
        <div class="col">
            <h2>Course Registration</h2>
            <p>Thank you for you interest in the {props.courseName} course.
                Please provide a bit of detail on why you would like to achieve this certification and
                one of our FireSmart Admins will review your application.</p>
            <form onSubmit={props.submitCallback}>
                <div class="mb-3">
                    <label for="enrollmentReason" class="form-label">Reason for Enrollment</label>
                    <textarea class="form-control" id="enrollmentReason" rows="3" onChange={props.enrollmentReasonChangeCallback}></textarea>
                </div>
                <button class="btn-standard float-right margin-left-10">Submit</button>
                <button class="btn-standard float-right" onClick={props.cancelCallback}>Cancel</button>
            </form>
        </div>
    );
}

export default CourseRegister;
