import React from "react";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../shared/utils/api';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../shared/Constants';

function Audits() {
    const [audits, setAudits] = useState([]);

    const fetchAudits = () => {
        api.get('/audits').then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setAudits(response);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    useEffect(() => {
        fetchAudits()
    }, []);

    const navigate = useNavigate();

    const handleRowClick = (id) => {
        navigate(id.toString());
    }

    const handleAdd = () => {
        navigate('add');
    }

    return (
        <div>
            <h2>Assessments</h2>
            <table>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {audits.map((audit) =>
                        <tr key={audit.auditID} onClick={() => handleRowClick(audit.auditID)}>
                            <td>{audit.auditTitle}</td>
                            <td>{audit.auditDescription}</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <br />
            <button onClick={handleAdd}>Add</button>
        </div>
    );
}

export default Audits;
