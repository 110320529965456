import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../shared/utils/api';
import { formatDate } from '../shared/utils/date';
import ReactDataTables from '../shared/components/ReactDataTables';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../shared/Constants';
import { isUserAdmin } from './../shared/utils/authToken'

function Enrollment() {
    const [certEntitlements, setCertEntitlements] = useState([]);
    const [enrollmentRequests, setEnrollmentRequests] = useState([]);
    const [approvalRequests, setApprovalRequests] = useState([]);

    const navigate = useNavigate();

    const fetchEnrollmentRequests = () => {
        api.get('/certification-entitlements').then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setCertEntitlements(response);

                let enrollmentRequests = response.filter((entitlement) => {
                    return entitlement.certificationStatus === "Requested";
                }
                );
                setEnrollmentRequests(enrollmentRequests);

                let approvalRequests = response.filter((entitlement) => {
                    return entitlement.certificationStatus === "Ready for Approval";
                }
                );
                setApprovalRequests(approvalRequests);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }


    const handleRowClick = (data) => {
        navigate(data.entitlementID.toString());
    }

    const countApprovalRequests = () => {
        if (approvalRequests.length == 0) {
            return null;
        }
        return approvalRequests.length;
    }

    const countEnrollmentRequests = () => {
        if (enrollmentRequests.length == 0) {
            return null;
        }
        return enrollmentRequests.length;
    }

    const columns = [
        { data: "certificationName", title: "Certification" },
        {
            data: "userFirstName", title: "User",
            render: function (data, _, row) {
                return data + " " + row.userLastName;
            }
        },
        { data: "certificationStatus", title: "Request Status" },
        {
            data: "requestDate", title: "Request Date",
            render: function (data) {
                return formatDate(data);
            }
        }
    ];

    const EnrollmentTable = () => {
        return <ReactDataTables data={enrollmentRequests} columns={columns}
            paging={false} scrollY={"30vh"}
            info={false}
            searching={false}
            order={[[3, "desc"]]}
            rowClickCallback={handleRowClick} />;
    };

    const ApprovalTable = () => {
        return <ReactDataTables data={approvalRequests} columns={columns}
            paging={false} scrollY={"30vh"}
            info={false}
            searching={false}
            order={[[3, "desc"]]}
            rowClickCallback={handleRowClick} />;
    }

    useEffect(() => {
        if (!isUserAdmin()) {
            navigate('/');
        }
        fetchEnrollmentRequests()
    }, []);

    return (
        <div>
            <h2>Course Enrollment Requests <span class="fs-badge large">{countEnrollmentRequests()}</span></h2>
            <EnrollmentTable />
            <br />
            <h2>Certification Approval Requests <span class="fs-badge large">{countApprovalRequests()}</span></h2>
            <ApprovalTable />
        </div>
    );
}

export default Enrollment;
