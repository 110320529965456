import React from 'react';
import CourseStatus from './CourseStatuse';
import { MdOutlineArrowOutward } from "react-icons/md";
function CourseListing({ course, status, badgeColour, handleRowClick, ...props }) {
    return (
        <button className="course-listing border-1 pt-0 border-border rounded-md overflow-hidden relative flex flex-col group h-auto items-start mb-auto" onClick={() => handleRowClick(course)} {...props}>
            <div className="relative w-full pb-[66%] top-0">
             <CourseStatus status={status} />
                <img className="object-cover absolute inset-0 w-full h-full" src={course.presignedURL} alt={course.courseTitle} />
            </div>            
            <div className="course-listing-content">
                <h3 className="no-margin-top">
                    {course.courseTitle}&nbsp;
                    {course.certificationBadgeColour && <i className="fa-solid fa-scroll" style={{ 'color': course.certificationBadgeColour }}></i>}
                </h3>
                <p>{course.courseDescription}</p>
                <p class="btn-standard btn-black group-hover:bg-gray-300 group-hover:text-slate rounded-md px-3 pt-2.5 pb-2 inline-block mb-0">Explore Course <MdOutlineArrowOutward className="ml-2 h-4 w-4 -mt-0.5 inline-block" /></p>
            </div>
        </button>
    );
}

export default CourseListing;
