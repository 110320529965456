
function ImageFull({ imageURL }) {

    return (
        <div className="row margin-bottom-20">
            <img class="audit-image-full" src={imageURL} />
        </div>
    );
}

export default ImageFull;
