import React from 'react';

function FormControlInput({ label, labelBolded, name, type, value, placeholder, onChange, disabled, ...props }) {
    return (
        <div class="form-group">
            <label class={labelBolded ? "form-label font-weight-bold no-print" : "form-label no-print"} htmlFor={name}>{label}</label>
            <input
                class="form-control"
                name={name}
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                disabled={disabled ? true : false}
                {...props}
            />
        </div>
    );
}

export default FormControlInput;
