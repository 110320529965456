import React, { useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../shared/utils/api";
import { toast } from "react-toastify";
import FormGroupInput from "../shared/components/FormGroupInput";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { ERROR_MESSAGE_DURATION } from "../shared/Constants";
import { IoPersonOutline } from "react-icons/io5";
import { IoLockClosedOutline } from "react-icons/io5";

function Login({ loginCallBack }) {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const toastID = useRef(null);

  let [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const login = () => {
    toastID.current = toast("Logging in...", { autoClose: false });
    api.post("/login", { email, password }).then(
      ({ token, redirectToProfile }) => {
        toast.update(toastID.current, {
          render: "Logged In!",
          type: "success",
          autoClose: 1000,
        });
        loginCallBack(token.token);
        navigate(
          redirectToProfile
            ? "/user-profile"
            : searchParams.get("redirectURL") || "/",
        );
      },
      () => {
        toast.update(toastID.current, {
          render: "Error Logging In",
          type: "error",
          autoClose: ERROR_MESSAGE_DURATION,
        });
      },
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return; // Return if validation failed
    }

    setValidated(true);
    login();
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    navigate("/signup");
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    navigate("/forgot-password");
  };

  return (
    <div
      id="login-container"
      class="position-absolute top-50 start-50 translate-middle flex"
    >
      <div class="form-container rounded-md w-[450px] !max-w-[80vw] p-4 box-content">
        <h3 className="text-center">Login</h3>
        <div className="input-container">
          <Form className="w-full" noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <FormGroupInput
                controlId="email"
                label="Email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required={true}
                invalidFeedback="Please enter a email."
                className="w-full"
                placeholder="Enter your email"
              />
            </Row>
            <Row>
              <FormGroupInput
                controlId="password"
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required={true}
                invalidFeedback="Please enter a password."
                className="w-full"
                placeholder="Enter your password"
              />
            </Row>
            <Row className="mt-3">
              <div class="col">
              <Button className="btn-standard w-full" type="submit">
                Login
              </Button>
              </div>
            </Row>
            <Row>
              <Form.Group class="mt-4 form-group flex flex-row justify-between mb-0">
                {/* <a onClick={handleSignUp} className="clickable">
                  Sign Up
                </a> */}
           
                <a onClick={handleForgotPassword} className="clickable">
                  Forgot Password?
                </a>
               
              </Form.Group>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;
